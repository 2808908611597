import axios from 'axios'
import { useEffect, useState } from 'react'
import { Address } from '../dto/account'
import { getAccessToken } from '../aad'
import apiProxy from './apiProxy'

export const lookupAddress = apiProxy('lookupAddress', async (query: string, countrySet?: string, idxSet?: string): Promise<Address[]> => {
  await getAccessToken()

  const params: string[] = [`query=${encodeURIComponent(query)}`]
  if (countrySet) params.push(`countrySet=${encodeURIComponent(countrySet)}`)
  if (idxSet) params.push(`idxSet=${encodeURIComponent(idxSet)}`)
  const { data } = await axios.get<any>(`${process.env.REACT_APP_ACCOUNT_API}/maps?${params.join('&')}`)

  return data.results.map((item: any) => ({
    ...(item.address || {}),
    type: item.type,
    lat: item.position?.lat,
    lon: item.position?.lon
  }))
})

export const getGeolocation = apiProxy('getGeolocation', async (fields?: string): Promise<any> => {
  return {
    country_code: 'US'
  }
  /*const params: string[] = [`api_key=${process.env.REACT_APP_IP_LOOKUP_KEY}`]
  if (fields) params.push(`fields=${encodeURIComponent(fields)}`)
  try {
    const { data } = await axios.get<any[]>(`https://ipgeolocation.abstractapi.com/v1/?${params.join('&')}`)
    return data
  } catch {
    return []
  }*/
})

export const getAllCountries = apiProxy('getAllCountries', async (): Promise<any[]> => {
  const ax = axios.create()
  ax.defaults.headers.common = {}
  try {
    const { data } = await ax.get<any>('https://api.countrylayer.com/v2/all?access_key=c4ed07886e8b517e49f3ca7cc9319a28')
    if (Array.isArray(data)) return data

    return [
      {
        name: 'United States of America',
        alpha2Code: 'US',
        alpha3Code: 'USA'
      }
    ]
  } catch (err) {
    return [
      {
        name: 'United States of America',
        alpha2Code: 'US',
        alpha3Code: 'USA'
      }
    ]
  }
})

let theCountries: any[] | undefined
export const useCountries = () => {
  const [countries, setCountries] = useState(theCountries)

  useEffect(() => {
    if (countries) return
    getAllCountries().then((result) => {
      theCountries = result.sort((a, b) => a.name.localeCompare(b.name))
      setCountries(theCountries)
    })
  }, [])

  return countries
}
