import React, { useEffect, useState } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import './style.scss'

export const savingAsyncAtom = atom<boolean>({
  key: 'savingAsync',
  default: false
})

export const Saving: React.FC = () => {
  const isSaving = useRecoilValue(savingAsyncAtom)

  const [progress, setProgress] = useState(-1)

  useEffect(() => {
    setProgress(isSaving ? 0 : -1)
  }, [isSaving, setProgress])

  useEffect(() => {
    if (progress < 0 || progress >= 1) return
    setTimeout(() => {
      if (progress < 0) return
      setProgress((p) => Math.min(1, p + 0.005))
    }, 10)
  }, [progress, setProgress])

  if (progress < 0) return null

  return <div className="ui-saving" style={{ width: document.body.clientWidth * progress }} />
}

export const useSaving = () => useRecoilState(savingAsyncAtom)
