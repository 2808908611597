import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import './style.scss'
import { logout } from 'services/src/aad'

const NetworkError = () => {
  const { t } = useTranslation()

  const elm = useRef<HTMLDivElement>(document.createElement('div'))
  React.useEffect(() => {
    document.body.appendChild(elm.current)
    return () => {
      if (elm.current) document.body.removeChild(elm.current)
    }
  }, [])

  if (!elm.current) return null

  return ReactDOM.createPortal(
    <div className="ui-network-error">
      <div>
        <div className="message">
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div>
            <h3>{t('General.Errors.NetworkFailTitle')}</h3>
            <p>{t('General.Errors.NetworkFail')}</p>
          </div>
        </div>
        <div className="buttons">
          <button type="button" className="ui-btn ui-btn-danger" onClick={() => window.location.reload()}>
            {t('General.Retry')}
          </button>
          <button
            type="button"
            className="ui-btn ui-btn-danger"
            onClick={() => {
              // Just in case!
              logout().finally(() => {
                window.location.href = `${process.env.REACT_APP_CC_SITE}`
              })
            }}
          >
            {t('General.Exit')}
          </button>
        </div>
      </div>
    </div>,
    elm.current
  )
}
export default NetworkError
