import React from 'react'
import './style.scss'

export interface CardSelectProps {
  variant?: 'primary' | 'secondary' | 'danger' | 'warn' | 'info' | 'success'
  items: string[]
  selected: number[]
  disabled?: boolean
  readonly?: boolean
  onClick: (idx: number) => void
}

export const CardSelect: React.FC<CardSelectProps> = ({ variant, items, selected, onClick, disabled, readonly }) => (
  <div className={`ui-card-select ui-row${variant ? ` ui-card-select-${variant}` : ''}`}>
    {items.map((item, idx) => {
      const isSelected = selected.includes(idx)

      return (
        <div key={idx} className="ui-col" style={{ width: `${100 / items.length}%` }}>
          <div
            className={`ui-card-select-card${isSelected ? ' selected' : ''}${disabled ? ' ui-disabled' : ''}${readonly ? ' ui-readonly' : ''}`}
            role="radio"
            aria-checked={isSelected}
            tabIndex={0}
            onClick={() => {
              if (disabled || readonly) return
              onClick(idx)
            }}
            onKeyDown={() => {}}
          >
            <div>{item}</div>
          </div>
        </div>
      )
    })}
  </div>
)
