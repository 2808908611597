import { Address, Client, Office, User } from './account'
import { FeeType, PhaseTypeKey, ProjectStatus, ProjectType } from './projectShare'

export * from './projectShare'

export interface ProjectName {
  id?: string
  status?: ProjectStatus
  name?: string
}

export interface Project extends ProjectName {
  statusHistory?: ProjectStatusHistory[]
  constructionType?: string
  deliveryMethod?: string
  procurementMethod?: string
  estimateType?: string
  projectType?: ProjectType
  account?: ProjectAccount
  provider?: ProjectAccount
  office?: Office
  client?: Client
  location?: Address
  description?: string
  projectNumber?: string
  taskOrderNumber?: string
  budget?: Budget
  phases?: Phase[]
  users?: User[]
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
  submittedAt?: string
  feeGeneratedAt?: string
  feeExpiresAt?: string
  feeApprovedAt?: string
}

export interface ProjectStatusHistory {
  byUserId: string
  createdAt: Date
  metadata: { [key: string]: any }
  newStatus: ProjectStatus
  oldStatus: ProjectStatus
}

export interface ProjectAccount {
  id?: string
  companyName?: string
  logoUrl?: string
}

export interface Budget {
  isFixed?: boolean
  fixed?: number
  range?: string
}

export interface Phase {
  id?: string
  status?: ProjectStatus
  statusHistory?: ProjectStatusHistory[]
  name?: string
  description?: string
  phaseType?: PhaseTypeKey
  sequence?: number
  submissionDate?: Date
  deliveryDate?: Date
  users?: User[]
  estimates?: Estimate[]
  boeId?: string
  fee?: number
  feeWeight?: number
  manualFee?: number
  manualFeeWeight?: number
  negotiatedFee?: number
  feeType?: FeeType
  feeExpires?: Date
  generatedTotals?: PhaseTotals
  manualTotals?: PhaseTotals
  negotiatedTotals?: PhaseTotals
  variations?: PhaseVariations[]
  otherFees?: OtherFee[]
  providerFee?: number;
  proEst?: {
    id: number
    createdAt: string
    createdById: string
  }
  pages?: number
  unclassifiedPages?: number
  manualEstimate?: {
    total?: number
    createdById?: string
    createdAt?: Date
    scopeDate?: Date
  }
}
export interface PhaseVariations {
  description?: string
}
export interface PhaseTotals {
  cost: number
  margin: number
  otherFeeTotal: number
  hours: number
  pages: number
  subtotal: number
  total: number
  hourlyRate: number
}

export interface Estimate {
  id: string
  parentId?: string
  children?: Estimate[]
  discipline: string
  baseRate: number
  levelOfEffortHours: number
  manualBaseRate: number
  manualLevelOfEffortHours: number
  negotiatedBaseRate: number
  negotiatedLevelOfEffortHours: number
  otherFees: OtherFee[]
  difficulty: number
  pages: number
  generatedTotals?: EstimateTotals
  manualTotals?: EstimateTotals
  negotiatedTotals?: EstimateTotals
  labor?: EstimateLabor[]
}

export interface EstimateLabor {
  id: string | null
  userId: string | null
  user?: User
  rate: number
  hours?: number
  split?: number
  cost: number
  margin: number
  skills?: string[]
  status: ProjectStatus
  statusHistory: ProjectStatusHistory[]
  createdAt?: Date
  createdById?: string
}

export interface EstimateTotals {
  difficulty: number
  providerBaseRate: number
  providerRate: number
  cost: number
  margin: number
  otherFeeTotal: number
  hourlyRate: number
  hours: number
  pages: number
  subtotal: number
  total: number
}

export interface OtherFee {
  name: string
  description?: string
  amount?: number
}

export interface ProjectCounts {
  accountId: string
  providerId: string
  officeId?: string
  total: number
  draft: number
  submitted: number
  accepted: number
  feeReady: number
  feeCounter: number
  approved: number
  inProgress: number
  complete: number
  rejected: number
  canceled: number
}
