import React, { useEffect, useLayoutEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCircleNotch } from '@fortawesome/pro-regular-svg-icons'
import './style.scss'
import { useOverlayControl } from 'services/src/state'

export interface AutoCompleteItem {
  [key: string]: any
  label?: string
  value?: string
}
export type AutoCompleteProps = React.HTMLAttributes<HTMLDivElement> & {
  value: string
  onValueChange: (value: string) => void
  suggestions: AutoCompleteItem[]
  loading?: boolean
  onSelectSuggestion?: (suggestion: any) => void
  placeholder?: string
  render?: (item: any) => JSX.Element
  disabled?: boolean
  suppressSearchIcon?: boolean
  searchIconUrl?: string
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  value,
  onValueChange,
  suggestions,
  loading,
  placeholder,
  render,
  onSelectSuggestion,
  className,
  disabled,
  suppressSearchIcon,
  searchIconUrl,
  ...props
}) => {
  const { addOverlay, removeOverlay } = useOverlayControl()

  const [isOpen, setIsOpen] = React.useState(false)
  const [highlight, setHighlight] = React.useState(-1)

  const itemsRef = React.useRef<HTMLDivElement>(null)
  const selectRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHighlight(-1)
    setIsOpen(!!suggestions?.length)
  }, [suggestions, setHighlight])

  const selectedItem = React.useCallback(
    (e: React.MouseEvent, suggestion: any) => {
      e.preventDefault()
      e.stopPropagation()
      if (onSelectSuggestion) onSelectSuggestion(suggestion)
    },
    [onSelectSuggestion]
  )

  const itemsBlur = React.useCallback(() => setTimeout(() => setIsOpen(false), 250), [])

  useLayoutEffect(() => {
    if (itemsRef.current && selectRef.current) {
      if (isOpen) {
        addOverlay(AutoComplete.name)
        const { left, top, height, width } = selectRef.current.getBoundingClientRect()
        itemsRef.current.style.left = `${left}px`
        itemsRef.current.style.top = `${top + height}px`
        itemsRef.current.style.minWidth = `${width}px`
        itemsRef.current.style.width = `${width}px`
      } else removeOverlay(AutoComplete.name)
    }

    return () => {
      removeOverlay(AutoComplete.name)
    }
  }, [isOpen, selectRef, itemsRef, addOverlay, removeOverlay])

  useLayoutEffect(() => {
    if (!itemsRef.current || !isOpen) return

    let height = 0
    let selectedTop = 0
    for (let i = 0; i < itemsRef.current.children.length; i++) {
      const c = itemsRef.current.children[i]
      const rect = c.getBoundingClientRect()
      if (c.className.includes('selected')) selectedTop = height
      height += rect.height
    }
    const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
    const maxHeight = Math.ceil(avgItemHeight * 5.5)
    itemsRef.current.style.maxHeight = `${maxHeight}px`

    if (selectedTop > maxHeight - avgItemHeight) {
      itemsRef.current.scrollTop = selectedTop - (maxHeight - avgItemHeight * 2)
    } else itemsRef.current.scrollTop = 0
  }, [isOpen, itemsRef])

  const keyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      if (e.code === 'ArrowDown') {
        e.preventDefault()
        e.stopPropagation()
        if (suggestions.length <= 0) return

        if (highlight < 0) setHighlight(0)
        if (highlight < suggestions.length - 1) {
          const h = highlight + 1
          setHighlight(h)

          if (!itemsRef.current) return

          let height = 0
          let highlightTop = 0
          for (let i = 0; i < itemsRef.current.children.length; i++) {
            const c = itemsRef.current.children[i]
            const rect = c.getBoundingClientRect()
            if (i === h) highlightTop = height
            height += rect.height
          }
          const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
          const maxHeight = Math.ceil(avgItemHeight * 5.5)

          if (highlightTop > maxHeight - avgItemHeight) {
            itemsRef.current.scrollTop = highlightTop - (maxHeight - avgItemHeight * 2)
          }
        }
      } else if (e.code === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()
        if (suggestions.length <= 0) return

        if (highlight < 0) setHighlight(suggestions.length - 1)
        if (highlight > 0) {
          const h = highlight - 1
          setHighlight(h)

          if (!itemsRef.current) return

          let height = 0
          let highlightTop = 0
          for (let i = 0; i < itemsRef.current.children.length; i++) {
            const c = itemsRef.current.children[i]
            const rect = c.getBoundingClientRect()
            if (i === h) highlightTop = height
            height += rect.height
          }
          const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
          const maxHeight = Math.ceil(avgItemHeight * 5.5)

          if (highlightTop > 0) {
            itemsRef.current.scrollTop = highlightTop - (maxHeight - avgItemHeight * 2)
          }
        }
      } else if (e.code === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        if (selectRef.current) selectRef.current.focus()
      } else if (e.code === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        if (highlight < 0) return
        if (onSelectSuggestion) onSelectSuggestion(suggestions[highlight])
        if (selectRef.current) selectRef.current.focus()
      }
    },
    [suggestions, highlight, setHighlight, onSelectSuggestion, setHighlight, disabled]
  )

  return (
    <div className={`ui-auto-complete${disabled ? ' disabled' : ''} ${className || ''}`} tabIndex={-1} role="searchbox" onFocus={() => {}} onKeyDown={keyDown} ref={selectRef} {...props}>
      {!suppressSearchIcon && <div className="search-icon">{searchIconUrl ? <div style={{ backgroundImage: `url("${searchIconUrl}")` }} /> : <FontAwesomeIcon icon={faSearch} />}</div>}

      {loading && (
        <div className="search-loading">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      )}

      <input
        type="text"
        className="input-item"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="new"
        tabIndex={0}
        onBlur={itemsBlur}
        onChange={(e) => onValueChange(e.currentTarget.value)}
      />

      <div className={`items ${isOpen && suggestions?.length ? 'open' : ''}`} role="menu" tabIndex={0} ref={itemsRef} onBlur={itemsBlur}>
        {(suggestions || []).map((suggestion, idx) => (
          <div key={idx} className={`${highlight === idx ? 'highlight' : ''}`} role="menuitem" tabIndex={-1} onKeyDown={() => {}} onMouseDown={(e) => selectedItem(e, suggestion)}>
            {render ? render(suggestion) : suggestion.label}
          </div>
        ))}
      </div>
    </div>
  )
}
