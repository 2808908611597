import axios, { CancelToken } from 'axios'
import { Project, ProjectName } from '../dto/project'
import { getAccessToken } from '../aad'
import apiProxy from './apiProxy'
import { ResourceUserRole } from '../dto/account'
import { Patch } from '../dto/common'
import { ProjectContent } from '../dto/content'
import { ProEstEstimate, ProEstEstimateDetails, ProEstTeamEx } from '../dto/proEst'
import { ProjectEx } from '../state'

export const createProject = async (accountId: string, model: Project, l?: string): Promise<Project> => {
  await getAccessToken()

  const p = { ...model }
  if (model.office) {
    p.office = {
      id: model.office.id,
      name: model.office.name
    }
  }

  const { data } = await axios.post(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects${l ? `?l=${l}` : ''}`, p)
  return data
}

export const updateProject = async (accountId: string, projectId: string, model: Project, l?: string): Promise<Project> => {
  await getAccessToken()

  const p = { ...model }
  if (model.office) {
    p.office = {
      id: model.office.id,
      name: model.office.name
    }
  }

  const { data } = await axios.put(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}${l ? `?l=${l}` : ''}`, p)
  return data
}

export const patchProject = async (accountId: string, projectId: string, patch: Patch[], l?: string): Promise<Project> => {
  await getAccessToken()
  const { data } = await axios.patch<Project>(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}${l ? `?l=${l}` : ''}`, patch)
  return data
}

export const getProjects = apiProxy(
  'getProjects',
  async (accountId: string | null, officeId: string | null, offset: number, limit: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; projects: Project[] }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset}`, `limit=${limit}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    if (officeId) q.push(`office=${officeId}`)

    const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId || 'provider'}/projects${q.length ? `?${q.join('&')}` : ''}`)
    return data
  }
)

export const getProjectNames = apiProxy('getProjectNames', async (accountId: string | null): Promise<ProjectName[]> => {
  await getAccessToken()

  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId || 'provider'}/projects/names`)
  return data
})

export const getProject = apiProxy('getProject', async (accountId: string, projectId: string, l?: string): Promise<ProjectEx> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}${l ? `?l=${l}` : ''}`)
  return data
})

export interface UploadFileEvent {
  state: 'progress' | 'complete' | 'cancel' | 'error'
  name: string
  progress: number
  complete?: boolean
  cancel?: string
  error?: any
  content?: ProjectContent
}

interface UploadBlock {
  state: 'pending' | 'upload' | 'complete'
  blockId: string
  blob: Blob
  size: number
}

export const uploadFile = async (
  accountId: string,
  projectId: string,
  category: string,
  phaseId: string | null,
  file: File,
  onUploadProgress: (e: UploadFileEvent) => void,
  cancelToken?: CancelToken,
  estimateId?: string,
  metadata?: { [key: string]: string }
): Promise<void> => {
  await getAccessToken()

  // const startTime = Date.now();

  const { data: start } = await axios.post<{
    contentId: string
    uri: string
  }>(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
    step: 'start',
    category,
    phaseId,
    estimateId,
    fileName: file.name,
    contentType: file.type,
    size: file.size,
    metadata
  })
  const { contentId } = start
  const sasUri = start.uri

  // Split file into blocks
  const blocks = ((): UploadBlock[] => {
    const pad = (number: number, length: number) => {
      let str = `${number}`
      while (str.length < length) str = `0${str}`
      return str
    }

    let remaining = file.size
    let maxBlockSize = 2 * 1024 * 1024 // 2MB
    if (remaining < maxBlockSize) maxBlockSize = remaining
    let offset = 0

    let index = 1
    const theBlocks: UploadBlock[] = []
    while (remaining > 0) {
      theBlocks.push({
        state: 'pending',
        blockId: btoa(`block-${pad(index, 6)}`),
        blob: file.slice(offset, offset + maxBlockSize),
        size: maxBlockSize
      })

      offset += maxBlockSize
      remaining -= maxBlockSize
      if (remaining <= 0) break
      if (remaining < maxBlockSize) maxBlockSize = remaining

      index++
    }
    return theBlocks
  })()

  let inCommit = false

  const uploadAxios = axios.create()
  delete uploadAxios.defaults.headers.common.Authorization

  // Add cleanup if user closed window be we complete.
  const windowUnload = () => {
    // Let back-end know we canceled!
    axios.post(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
      step: 'cancel',
      category,
      phaseId,
      contentId
    })
  }
  window.addEventListener('unload', windowUnload)

  const commitBlockList = () => {
    const uri = `${sasUri}&comp=blocklist`
    const requestBody = `<?xml version="1.0" encoding="utf-8"?><BlockList>${blocks.map((block) => `<Latest>${block.blockId}</Latest>`).join('')}</BlockList>`

    return uploadAxios.put(uri, requestBody, {
      headers: {
        'x-ms-blob-content-type': file.type
      }
    })
  }

  const loadEventHandler = (index: number, e: ProgressEvent<FileReader>, uploadBlock: (index: number) => void) => {
    if (!e.target?.result) return

    const a = e.target.result as ArrayBuffer
    const requestData = new Uint8Array(a)

    blocks[index].state = 'upload'
    const uri = `${sasUri}&comp=block&blockid=${blocks[index].blockId}`

    uploadAxios
      .put(uri, requestData, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': 'application/pdf'
        },
        cancelToken
      })
      .then(() => {
        blocks[index].state = 'complete'

        const completeCount = blocks.filter((x) => x.state === 'complete').length

        onUploadProgress({
          state: 'progress',
          name: file.name,
          progress: Math.min(1, completeCount / blocks.length)
        })

        // Done?
        if (completeCount >= blocks.length) {
          if (inCommit) return

          inCommit = true
          commitBlockList()
            .then(() =>
              axios.post<ProjectContent>(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
                step: 'complete',
                category,
                phaseId,
                contentId
              })
            )
            .then(({ data: content }) => {
              onUploadProgress({
                state: 'complete',
                name: file.name,
                progress: 1,
                content
              })
              // console.log(`Total Upload Time: ${Date.now() - startTime}ms`);
            })
            .catch((err) => {
              // Let back-end know we failed!
              axios
                .post(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
                  step: 'error',
                  category,
                  phaseId,
                  contentId
                })
                .finally(() =>
                  onUploadProgress({
                    state: 'error',
                    name: file.name,
                    progress: 0,
                    error: err
                  })
                )
            })
            .finally(() => {
              // Remove the unload event handler
              window.removeEventListener('unload', windowUnload)
            })

          return
        }

        const nextIndex = blocks.findIndex((x) => x.state === 'pending')
        if (nextIndex < 0) return

        // Start the next block!
        uploadBlock(nextIndex)
      })
      .catch((err) => {
        // Remove the unload event handler
        window.removeEventListener('unload', windowUnload)

        // Canceled?
        if (!err.message) {
          // Let back-end know we failed!
          axios
            .post(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
              step: 'cancel',
              category,
              phaseId,
              contentId
            })
            .finally(() => onUploadProgress({ state: 'cancel', name: file.name, progress: 0 }))
          return
        }

        // Let back-end know we failed!
        axios
          .post(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
            step: 'error',
            category,
            phaseId,
            contentId
          })
          .finally(() =>
            onUploadProgress({
              state: 'error',
              name: file.name,
              progress: 0,
              error: err
            })
          )
      })
  }

  const errorEventHandler = (e: ProgressEvent<FileReader>) => {
    if (!e.target?.error) return

    const { error } = e.target

    // Let back-end know we failed!
    axios
      .post(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/direct`, {
        step: 'error',
        category,
        phaseId,
        contentId
      })
      .finally(() =>
        onUploadProgress({
          state: 'error',
          name: file.name,
          progress: 0,
          error
        })
      )

    // Remove the unload event handler
    window.removeEventListener('unload', windowUnload)
  }

  const uploadBlock = (index: number) => {
    const r = new FileReader()

    r.onerror = errorEventHandler
    r.onload = (e) => loadEventHandler(index, e, uploadBlock)
    r.readAsArrayBuffer(blocks[index].blob)
  }

  // Kick max of 20!
  for (let i = 0; i < Math.min(blocks.length, 20); i++) {
    uploadBlock(i)
  }
}

export const deleteFile = async (accountId: string, projectId: string, contentId: string) => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}/${contentId}`)
}

export const deleteProject = async (accountId: string, projectId: string, force?: boolean): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}${force ? '?force=true' : ''}`)
}

export const updateProjectUsers = apiProxy('updateProjectUsers', async (accountId: string, projectId: string, users: { userId: string; role: ResourceUserRole }[]): Promise<void> => {
  await getAccessToken()
  await axios.post(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}/users`, users)
})

export const updateProjectUserRole = apiProxy('updateProjectUserRole', async (accountId: string, projectId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken()
  await axios.put(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}/users/${userId}?role=${role}`)
})

export const deleteProjectUser = apiProxy('deleteProjectUser', async (accountId: string, projectId: string, userId: string): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}/users/${userId}`)
})

export const updatePhaseUsers = apiProxy('updatePhaseUsers', async (accountId: string, projectId: string, phaseId: string, users: { userId: string; role: ResourceUserRole }[]): Promise<void> => {
  await getAccessToken()
  await axios.post(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/phases/${phaseId}/users`, users)
})

export const updatePhaseUserRole = apiProxy('updatePhaseUserRole', async (accountId: string, phaseId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken()
  await axios.put(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/phases/${phaseId}/users/${userId}?role=${role}`)
})

export const deletePhaseUser = apiProxy('deletePhaseUser', async (accountId: string, phaseId: string, userId: string): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/phases/${phaseId}/users/${userId}`)
})

export const getProEst = apiProxy('getProEst', async (accountId: string, projectId: string, phaseId: string): Promise<{ estimate: ProEstEstimate; details: ProEstEstimateDetails }> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}/phases/${phaseId}/proest`)
  return data
})

export const createProEst = apiProxy('createProEst', async (accountId: string, projectId: string, phaseId: string): Promise<{ estimate: ProEstEstimate; details: ProEstEstimateDetails }> => {
  await getAccessToken()
  const { data } = await axios.post(`${process.env.REACT_APP_PROJECT_API}/accounts/${accountId}/projects/${projectId}/phases/${phaseId}/proest`)
  return data
})

export const getProEstTeam = apiProxy('getProEstTeam', async (): Promise<ProEstTeamEx[]> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/proest/team`)
  return data
})
