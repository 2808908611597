import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheckCircle, faBell } from '@fortawesome/pro-regular-svg-icons'
import { SeverityLevel } from '@microsoft/applicationinsights-common'
import { useTelemetry } from 'services/src/telemetry'

import { ALERT_TIMEOUT } from '../constants'
import './style.scss'

let alertId = moment().valueOf()

export const alertAtom = atom<Alert[]>({
  key: 'alerts',
  default: []
})

export interface Alert extends AlertParams {
  id: number
  type: 'danger' | 'warn' | 'info' | 'success' | 'default'
  added: number
  overlayDone?: boolean
}

export interface AlertParams {
  message: string | JSX.Element
  title?: string | JSX.Element
  noAutoHide?: boolean
  error?: any
}

interface ReadyAlert {
  message: {
    short?: string | JSX.Element
    full: string | JSX.Element
  }
  error?: {
    short?: string | JSX.Element
    full: string | JSX.Element
  }
}

const AlertItem: React.FC<{ alert: Alert; showTime?: boolean; onRemove: (alert: Alert) => void }> = ({ alert, showTime, onRemove }) => {
  const { t } = useTranslation()

  const [moreMessage, setMoreMessage] = useState(false)
  const [moreError, setMoreError] = useState(false)

  const [ready] = useState<ReadyAlert>(() => {
    const ready: ReadyAlert = {
      message: { full: alert.message }
    }
    if (typeof alert.message === 'string' && alert.message.length > 297) {
      ready.message.short = (
        <div>
          {`${alert.message.substring(0, 297)}...`}{' '}
          <span className="action" role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => setMoreMessage(true)}>
            {t('General.SeeMore')}
          </span>
        </div>
      )
      ready.message.full = (
        <div>
          {alert.message}{' '}
          <span className="action" role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => setMoreMessage(false)}>
            {t('General.SeeLess')}
          </span>
        </div>
      )
    }

    if (alert.error) {
      const e = process.env.NODE_ENV !== 'production' ? alert.error.stack || alert.error.message : alert.error.message
      ready.error = { full: e }
      if (e.length > 197) {
        ready.error.short = (
          <div>
            {`${e.substring(0, 197)}...`}{' '}
            <span className="action" role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => setMoreError(true)}>
              {t('General.SeeMore')}
            </span>
          </div>
        )
        ready.error.full = (
          <div>
            {e}{' '}
            <span className="action" role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => setMoreError(false)}>
              {t('General.SeeLess')}
            </span>
          </div>
        )
      }
    }

    return ready
  })

  if (!ready) return null

  return (
    <div key={alert.id} className={`ui-alerts-item ui-alert-${alert.type}`}>
      <button type="button" className="ui-alert-remove" tabIndex={-1} onClick={() => onRemove(alert)}>
        &times;
      </button>
      {alert.title && (
        <div className="ui-flex ui-flex-nowrap ui-text ui-text-bold" style={{ alignItems: 'center', marginBottom: 15 }}>
          <div>
            {alert.type === 'danger' && <FontAwesomeIcon icon={faExclamationTriangle} />}
            {alert.type === 'warn' && <FontAwesomeIcon icon={faExclamationTriangle} />}
            {alert.type === 'info' && <FontAwesomeIcon icon={faBell} />}
            {alert.type === 'success' && <FontAwesomeIcon icon={faCheckCircle} />}
          </div>
          <div style={{ marginLeft: 5, flex: 1 }}>{alert.title}</div>
        </div>
      )}

      <div className="ui-text-sm">
        {ready.message.short && !moreMessage && ready.message.short}
        {(!ready.message.short || moreMessage) && ready.message.full}
      </div>

      {ready.error && (
        <pre className="ui-text-muted ui-text-xs" style={{ marginTop: 10 }}>
          {ready.error.short && !moreError && ready.error.short}
          {(!ready.error.short || moreError) && ready.error.full}
        </pre>
      )}

      {showTime && (
        <div className="ui-text-muted ui-text-xs" style={{ marginTop: 10 }}>
          {moment(alert.added).format('LLL')}
        </div>
      )}
    </div>
  )
}

export const Alerts: React.FC = () => {
  const { t } = useTranslation()
  const [alerts, setAlerts] = useRecoilState(alertAtom)

  useEffect(() => {
    const i = setInterval(() => {
      let diff = false
      const a = alerts.map((a) => {
        const x = { ...a }

        if (x.overlayDone) return x

        if (x.added > 0 && moment().valueOf() - x.added > ALERT_TIMEOUT) {
          x.overlayDone = true
          diff = true
        }
        return x
      })
      if (diff) setAlerts(a)
    }, 1000)
    return () => {
      clearInterval(i)
    }
  }, [alerts, setAlerts])

  if (alerts.filter((x) => !x.overlayDone).length <= 0) return null

  return (
    <div className="ui-alerts">
      <button
        type="button"
        className="ui-alerts-clear-all"
        onClick={() => {
          setAlerts((old) =>
            old.map((a) => {
              const x = { ...a }
              x.overlayDone = true
              return x
            })
          )
        }}
      >
        {t('General.Close')}
      </button>
      {alerts
        .filter((alert) => !alert.overlayDone)
        .map((alert) => (
          <AlertItem
            key={alert.id}
            alert={alert}
            onRemove={(alert) => {
              setAlerts((old) =>
                old.map((a) => {
                  const x = { ...a }
                  if (x.id === alert.id) x.overlayDone = true
                  return x
                })
              )
            }}
          />
        ))}
    </div>
  )
}

export const AlertsDrawer: React.FC = () => {
  const { t } = useTranslation()
  const [alerts, setAlerts] = useRecoilState(alertAtom)
  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => setOpen((o) => alerts && !o), [setOpen, alerts])
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleOpen = useCallback(() => setOpen(alerts?.length > 0), [setOpen, alerts])

  useEffect(() => {
    if (alerts.length <= 0) setOpen(false)
  }, [alerts, setOpen])

  useEffect(() => {
    window.addEventListener('toggleAlertDrawer', handleToggle)
    window.addEventListener('toggleAlertDrawerOpen', handleOpen)
    window.addEventListener('toggleAlertDrawerClose', handleClose)
    return () => {
      window.removeEventListener('toggleAlertDrawer', handleToggle)
      window.removeEventListener('toggleAlertDrawerOpen', handleOpen)
      window.removeEventListener('toggleAlertDrawerClose', handleClose)
    }
  }, [handleToggle, handleOpen, handleClose])

  const readyAlerts = useMemo(() => [...alerts].sort((a, b) => b.id - a.id), [alerts])

  if (readyAlerts.length <= 0 || !open) return null

  return (
    <div className="ui-alert-drawer">
      <button type="button" className="ui-alerts-clear-all" onClick={() => setAlerts([])}>
        {t('General.ClearAll')}
      </button>

      <div>
        {readyAlerts.map((alert, idx) => (
          <div key={alert.id}>
            {idx > 0 && <hr />}
            <AlertItem
              alert={alert}
              showTime
              onRemove={(alert) => {
                setAlerts((old) => {
                  const a = [...old]
                  const idx = a.findIndex((x) => x.id === alert.id)
                  if (idx >= 0) a.splice(idx, 1)
                  return a
                })
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export interface AlertHook {
  alertDanger: (params: AlertParams) => number
  alertWarn: (params: AlertParams) => number
  alertInfo: (params: AlertParams) => number
  alertSuccess: (params: AlertParams) => number
  clearAlert: (id: number) => void
  clearAll: () => void
  count: number
}

export const useAlert = (): AlertHook => {
  const [alerts, setAlerts] = useRecoilState(alertAtom)
  const { telemetry } = useTelemetry()

  const alertDanger = useCallback(
    (params: AlertParams) => {
      const id = alertId++
      setAlerts((old) => [...old, { ...params, id, type: 'danger', added: params.noAutoHide === true ? -1 : moment().valueOf() }])
      if (params.error) {
        // eslint-disable-next-line no-console
        // console.error(params.error.stack || params.error);
        telemetry?.trackException({
          exception: params.error,
          severityLevel: SeverityLevel.Error
        })
      }
      return id
    },
    [setAlerts, telemetry]
  )

  const alertWarn = useCallback(
    (params: AlertParams) => {
      const id = alertId++
      setAlerts((old) => [...old, { ...params, id, type: 'warn', added: params.noAutoHide === true ? -1 : moment().valueOf() }])
      if (params.error) {
        // eslint-disable-next-line no-console
        // console.warn(params.error.stack || params.error);
        telemetry?.trackException({
          exception: params.error,
          severityLevel: SeverityLevel.Warning
        })
      }
      return id
    },
    [setAlerts, telemetry]
  )

  const alertInfo = useCallback(
    (params: AlertParams) => {
      const id = alertId++
      setAlerts((old) => [...old, { ...params, id, type: 'info', added: params.noAutoHide === true ? -1 : moment().valueOf() }])
      if (params.error) {
        // eslint-disable-next-line no-console
        // console.info(params.error.stack || params.error);
        telemetry?.trackException({
          exception: params.error,
          severityLevel: SeverityLevel.Information
        })
      }
      return id
    },
    [setAlerts, telemetry]
  )

  const alertSuccess = useCallback(
    (params: AlertParams) => {
      const id = alertId++
      setAlerts((old) => [...old, { ...params, id, type: 'success', added: params.noAutoHide === true ? -1 : moment().valueOf() }])
      return id
    },
    [setAlerts]
  )

  const clearAlert = useCallback(
    (id: number) => {
      setAlerts((current) => {
        const idx = current.findIndex((x) => x.id === id)
        if (idx < 0) return current

        const ax = [...current]
        ax.splice(idx, 1)

        if (!ax.length) window.dispatchEvent(new Event('toggleAlertDrawerClose'))

        return ax
      })
    },
    [setAlerts]
  )

  const clearAll = useCallback(() => {
    window.dispatchEvent(new Event('toggleAlertDrawerClose'))
    setAlerts([])
  }, [setAlerts])

  return { alertDanger, alertWarn, alertInfo, alertSuccess, clearAlert, clearAll, count: alerts.length }
}
