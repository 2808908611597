import axios, { AxiosResponse } from 'axios'

export * from './account'
export * from './addressLookup'
export * from './content'
export * from './project'
export * from './projectSupport'
export * from './projectStatistics'
export * from './smartDocs'

export const apiInterceptor = (success: (resp: AxiosResponse) => void, error: (err: any) => void) => {
  axios.interceptors.response.use(
    (resp) => {
      success(resp)
      return resp
    },
    (err) => {
      error(err)
      return Promise.reject(err)
    }
  )
}
