import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import axios from 'axios'
import { useTheme } from 'components/src/theme'
import { getAccounts, getAccountUsers, getClients, getMe, getUsers } from '../api'
import { Account, AccountType, AccountWithUsers, Client, Office, ResourceUserRole, User } from '../dto/account'
import { aadConfig, login, psa } from '../aad'
import { makeDashboardPath } from '../dom'

export const meAtom = atom<User | undefined>({
  key: 'me',
  default: undefined
})
let meLoading = false

export const userAccountsAtom = atom<Account[] | undefined>({
  key: 'userAccounts',
  default: undefined
})

export const currentAccountAtom = atom<Account | undefined>({
  key: 'currentUserAccount',
  default: undefined
})

export const currentAccountWithUsersAtom = atom<AccountWithUsers | undefined>({
  key: 'currentAccountWithUsers',
  default: undefined
})

// eslint-disable-next-line max-len
export const accountWithUsersAtom = atomFamily<AccountWithUsers | undefined, { accountId?: string; projectId?: string }>({
  key: 'accountWithUsers',
  default: undefined
})

export const userOfficesAccountIdAtom = atom<string | undefined>({
  key: 'userOfficesAccountId',
  default: undefined
})
export const userOfficesAtom = atom<Office[] | undefined>({
  key: 'userOffices',
  default: undefined
})
export const currentOfficeAtom = atom<Office | undefined>({
  key: 'currentUserOffice',
  default: undefined
})

export function useMe<T extends User = User>(noRedirect?: boolean): [me: T | undefined, refresh: () => Promise<T | undefined>, setMe: (user: T) => void] {
  const [current, setCurrent] = useRecoilState(meAtom)
  const account = useRecoilValue(currentAccountAtom)

  const { setTheme } = useTheme()

  const setMe = useCallback((user: T) => { setCurrent(user) }, [setCurrent])

  const refresh = useCallback(async () => {
    if (meLoading) return current as T

    const activeAccount = psa().getActiveAccount() || psa().getAllAccounts()[0]
    if (!activeAccount) {
      setCurrent(undefined)
      if (noRedirect) return current as T

      await login()
      return current as T
    }

    meLoading = true

    return psa()
      .acquireTokenSilent({
        account: activeAccount,
        scopes: aadConfig().scopes
      })
      .then((response) => {
        const { accessToken, account } = response

        psa().setActiveAccount(account!)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        return accessToken
      })
      .then(() => getMe(account?.type === 'Provider' ? account.id : undefined))
      .then((me) => {
        setTheme(me.theme || localStorage.getItem('theme') || ('light' as any))

        // if (me.userType )
        setCurrent({
          ...me,
          pictureUrl: me.pictureUrl ? `${me.pictureUrl}?_=${Date.now()}` : undefined
        })

        return me as T
      })
      .finally(() => {
        meLoading = false
      })
  }, [current, setCurrent, account])

  useEffect(() => {
    if (!account) return

    if (!current || (account.type === AccountType.Provider && current.providerId !== account.id)) refresh()
  }, [current, account, refresh])

  return [current as T, refresh, setMe]
}

export const useUserAccounts = (): [accounts: Account[] | undefined, refresh: (soft?: boolean) => Promise<void>] => {
  const [me] = useMe()
  const [current, setCurrent] = useRecoilState(userAccountsAtom)
  const [currentAccount, setCurrentAccount] = useRecoilState(currentAccountAtom)

  const refresh = useCallback((soft?: boolean) => {
    if (soft && current) return Promise.resolve()

    return getAccounts()
      .then((accounts) => {
        let theCurrentAccount: Account | undefined
        setCurrent(
          accounts.map((a) => {
            const a2 = { ...a }
            // Force logo update
            if (a2.logoUrl) a2.logoUrl = `${a2.logoUrl}?_=${Date.now()}`

            // Capture the current account
            if (a2.id === currentAccount?.id) theCurrentAccount = a2

            return a2
          })
        )
        return theCurrentAccount
      })
      .then((a) => {
        if (a) setCurrentAccount(a)
      })
      .catch(() => setCurrent([]))
  }, [current, setCurrent])

  useEffect(() => {
    refresh(true).then(() => {})
  }, [me])

  return [current, refresh]
}

export const useCurrentUserAccount = (): [Account | undefined, (account?: Account | string) => void] => {
  const [accounts] = useUserAccounts()
  const [current, setCurrent] = useRecoilState(currentAccountAtom)
  const history = useHistory()

  useEffect(() => {
    if (!accounts) return
    const id = localStorage.getItem('currentAccount')
    const a = accounts.find((x) => x.id === id) || (accounts.length > 0 ? accounts[0] : undefined)
    setCurrent(a)
    if (a) {
      localStorage.setItem('currentAccount', a.id)

      if (!window.location.pathname.startsWith('/dashboard')) return

      if (a.id !== 'provider' && !window.location.pathname.startsWith(`/dashboard/${a.id}`)) {
        history.replace(makeDashboardPath(a?.id))
      }
    }
  }, [accounts])

  const setCurrentCb = useCallback(
    (account?: Account | string) => {
      if (!accounts || !account) {
        setCurrent(undefined)
        localStorage.removeItem('currentAccount')
        return
      }

      const id = typeof account === 'string' ? account : account.id
      const a = accounts.find((x) => x.id === id)

      setCurrent(a)
      if (a) localStorage.setItem('currentAccount', a.id)
    },
    [accounts, setCurrent]
  )

  return [current, setCurrentCb]
}

export const useCurrentAccountWithUser = (projectId?: string): [AccountWithUsers | undefined, (noForce?: boolean) => void] => {
  const [account] = useCurrentUserAccount()
  const [current, setCurrent] = useRecoilState(currentAccountWithUsersAtom)

  const refreshCb = useCallback(
    (noForce?: boolean) => {
      if (!account) return
      if (noForce) if (current?.id === account.id) return

      getAccountUsers(account.id, projectId).then((accountWithUsers) => setCurrent(accountWithUsers))
    },
    [account, projectId, setCurrent]
  )

  useEffect(() => refreshCb(true), [account, projectId, setCurrent])

  return [current, refreshCb]
}

export const useAccountWithUser = (accountId?: string, projectId?: string): [AccountWithUsers | undefined, () => Promise<void>] => {
  const [current, setCurrent] = useRecoilState(accountWithUsersAtom({ accountId, projectId }))

  const refreshCb = useCallback(() => {
    if (!accountId) return Promise.resolve()

    return getAccountUsers(accountId, projectId).then((accountWithUsers) => setCurrent(accountWithUsers))
  }, [accountId, projectId, setCurrent])

  useEffect(() => {
    if (current) return
    refreshCb().then(() => {})
  }, [accountId, projectId])

  return [current, refreshCb]
}

export const useUserOffices = (): [offices: Office[] | undefined, refresh: () => void, inRefresh: boolean] => {
  const [me] = useMe()
  const [account] = useCurrentUserAccount()
  const [, refreshAccounts] = useUserAccounts()
  const [officesAccountId] = useRecoilState(userOfficesAccountIdAtom)
  const [current, setCurrent] = useRecoilState(userOfficesAtom)
  const [currentOffice, setCurrentOffice] = useRecoilState(currentOfficeAtom)

  useEffect(() => {
    if (!account || !me || account.id === officesAccountId) return

    const au = account.users?.find((x) => x.id === me.id)

    if (account.id === 'provider' || !account.offices) {
      setCurrent([])
      setCurrentOffice(undefined)
      return
    }

    const counts = (office: Office) => {
      const o = { ...office }
      if (!account.counts) return o
      o.counts = {
        projects: account.counts.officeProjects.find((x) => x.id === o.id)?.count || 0
      }
      return o
    }

    const offices = au?.role === ResourceUserRole.Contributor ? account.offices.filter((o) => !!o.users?.find((u) => u.id === me.id)).map(counts) : account.offices.map(counts)

    setCurrent(offices)
    setCurrentOffice(offices.find((o) => o.id === currentOffice?.id))
  }, [account, me])

  const [inRefresh, setInRefresh] = useState(false)

  const refresh = useCallback(() => {
    if (!account) return

    setInRefresh(true)
    refreshAccounts().finally(() => setInRefresh(false))
  }, [account])

  return [current, refresh, inRefresh]
}

export const useCurrentUserOffice = (): [Office | undefined, (office?: Office) => void] => {
  const [account] = useCurrentUserAccount()
  const [offices] = useUserOffices()
  const [current, setCurrent] = useRecoilState(currentOfficeAtom)

  useEffect(() => {
    if (!offices) return
    const id = localStorage.getItem(`currentOffice-${account?.id || ''}`)
    setCurrent(offices.find((x) => x.id === id))
  }, [offices])

  const setCurrentCb = useCallback(
    (office?: Office | string | null) => {
      if (!offices || !office) {
        setCurrent(undefined)
        localStorage.removeItem(`currentOffice-${account?.id || ''}`)
        return
      }

      const id = typeof office === 'string' ? office : office.id
      const o = offices.find((x) => x.id === id)

      setCurrent(o)
      if (!o?.id) localStorage.removeItem(`currentOffice-${account?.id || ''}`)
      else localStorage.setItem(`currentOffice-${account?.id || ''}`, o.id)
    },
    [account, offices, setCurrent]
  )

  return [current, setCurrentCb]
}

export const userClientsAtom = atom<Client[] | undefined>({
  key: 'userClients',
  default: undefined
})

export const useUserClients = (): [clients: Client[] | undefined, refresh: () => void] => {
  const [account] = useCurrentUserAccount()
  const [current, setCurrent] = useRecoilState(userClientsAtom)

  useEffect(() => {
    if (!account) return
    getClients(account.id).then(setCurrent)
  }, [account])

  const refresh = useCallback(() => {
    if (!account) return
    getClients(account.id).then(setCurrent)
  }, [account])

  return [current, refresh]
}

export const initProtectedState = (): Promise<{ me: User; accounts: Account[] }> => {
  const setMe = useSetRecoilState(meAtom)
  const setAccounts = useSetRecoilState(userAccountsAtom)

  return getMe().then((me) => {
    setMe(me)
    return getAccounts().then((accounts) => {
      setAccounts(accounts)
      return { me, accounts }
    })
  })
}

export enum App {
  client,
  provider,
  expert
}

export enum UserType {
  client,
  expert,
  provider
}

export interface Rights {
  app: App
  primaryProvider: boolean
  id: string
  userType: UserType
  isGuest?: boolean

  canListAccounts?: boolean
  canEditAccount?: boolean
  canDeleteAccount?: boolean
  canInviteAccount?: boolean
  canInviteAccountExpertOnly?: boolean

  canClassifyProjectType?: boolean

  canCreateProject?: boolean
  canInviteToProject?: boolean
  canUploadFile?: boolean
  canViewFees?: boolean

  canListOffices?: boolean
  canCreateOffice?: boolean
  canInviteToOffice?: boolean
  canEditOffice?: boolean
  canDeleteOffice?: boolean

  canListTx?: boolean
  canCreateTx?: boolean
  canEditTx?: boolean
  canDeleteTx?: boolean

  canListDocs?: boolean
  canCreateDoc?: boolean
  canEditDoc?: boolean
  canDeleteDoc?: boolean

  offices: { officeId: string; role: ResourceUserRole }[]
  clients: { clientId: string; role: ResourceUserRole }[]
  projects: {
    projectId: string
    role: ResourceUserRole
    phases: {
      phaseId: string
      role: ResourceUserRole
      estimates: {
        estimateId: string
        role: ResourceUserRole
      }[]
    }[]
  }[]
}

export const useRights = (officeId?: string, projectId?: string): Rights | undefined => {
  const [account] = useCurrentUserAccount()
  const [accountWithUsers] = useAccountWithUser(account?.id, projectId)
  const [me] = useMe()

  return useMemo<Rights | undefined>(() => {
    if (!accountWithUsers || !me) return undefined

    let userType = UserType.client
    if (accountWithUsers.type === AccountType.Provider) {
      userType = accountWithUsers.users.find((u) => u.id === me.id)?.role === ResourceUserRole.Contributor ? UserType.expert : UserType.provider
    }

    let app = App.client
    if (window.location.host.startsWith('provider')) app = App.provider
    else if (window.location.host.startsWith('expert')) app = App.expert

    const rights: Rights = {
      app,
      primaryProvider: app === App.provider && accountWithUsers.id === 'provider',
      userType,
      id: me.id,
      isGuest: true,
      offices: [],
      clients: [],
      projects: []
    }

    const au = accountWithUsers.users?.find((x) => x.id === me!.id)
    if (au) {
      if (accountWithUsers.type === AccountType.Provider) {
        if (au.role === ResourceUserRole.Owner || au.role === ResourceUserRole.Guest) {
          rights.canListAccounts = true
          rights.canListTx = true
        }
        if (au.role === ResourceUserRole.Owner) {
          rights.canEditAccount = true
          rights.canClassifyProjectType = true
          rights.canCreateTx = true
          rights.canEditTx = true
          rights.canDeleteTx = true
          // TODO Handle this!
          rights.canListDocs = true
          rights.canCreateDoc = true
          rights.canEditDoc = true
          rights.canDeleteDoc = true
        }
        if (au.role === ResourceUserRole.Guest) {
          rights.isGuest = true
        }
      } else {
        rights.canListOffices = true
      }

      if (au.role === ResourceUserRole.Owner) {
        rights.canEditAccount = true
        rights.canInviteAccount = true
        rights.canCreateProject = true
        rights.canInviteToProject = true
        rights.canCreateOffice = true
        rights.canEditOffice = true
        rights.canInviteToOffice = true
        rights.canUploadFile = true
        rights.canViewFees = true
        rights.canListTx = true

        if ((account?.offices?.length || 0) > 1) {
          rights.canDeleteOffice = true
        }

        rights.canDeleteAccount = true
      }
      if (au.role === ResourceUserRole.Administrator) {
        rights.canEditAccount = false
        rights.canInviteAccount = false
        rights.canCreateProject = false
        rights.canCreateOffice = false
        rights.canEditOffice = false
        rights.canInviteToOffice = false

        rights.canInviteToProject = true
        rights.canUploadFile = true

        rights.canInviteAccountExpertOnly = rights.canInviteAccount
      }
      if (au.role === ResourceUserRole.Contributor) {
        rights.canCreateOffice = true
      }

      rights.isGuest = au.role === ResourceUserRole.Guest
    }

    rights.offices = accountWithUsers.offices.map((o) => {
      const u = o.users.find((x) => x.id === me!.id)

      if (u) {
        rights.isGuest = u.role === ResourceUserRole.Guest
        rights.canListOffices = true
      }

      return {
        officeId: o.id,
        role: u?.role || ResourceUserRole.Guest,
        canCreateProject: u?.role === ResourceUserRole.Owner || u?.role === ResourceUserRole.Administrator,
        canDeleteProject: u?.role === ResourceUserRole.Owner
      }
    })
    rights.clients = accountWithUsers.clients.map((c) => {
      const u = c.users.find((x) => x.id === me!.id)

      if (u) {
        rights.isGuest = u.role === ResourceUserRole.Guest
      }

      return {
        clientId: c.id,
        role: u?.role || ResourceUserRole.Guest,
        canCreateProject: u?.role === ResourceUserRole.Owner || u?.role === ResourceUserRole.Administrator,
        canDeleteProject: u?.role === ResourceUserRole.Owner
      }
    })

    rights.projects = accountWithUsers.projects.map((p) => ({
      projectId: p.id,
      role: p.users.find((x) => x.id === me!.id)?.role || ResourceUserRole.Guest,
      phases: p.phases.map((ph) => ({
        phaseId: ph.id,
        role: ph.users.find((x) => x.id === me!.id)?.role || ResourceUserRole.Guest,
        estimates: ph.estimates.map((est) => ({
          estimateId: est.id,
          role: est.users.find((x) => x.id === me!.id)?.role || ResourceUserRole.Guest
        }))
      }))
    }))

    if (officeId) {
      const ou = accountWithUsers.offices?.find((x) => x.id === officeId)?.users.find((x) => x.id === me!.id)
      if (ou) {
        rights.canCreateProject = ou.role === ResourceUserRole.Owner || ou.role === ResourceUserRole.Administrator || ou.role === ResourceUserRole.Contributor
        rights.canEditOffice = ou.role === ResourceUserRole.Owner || ou.role === ResourceUserRole.Administrator
        rights.canInviteToOffice = rights.canEditOffice

        rights.isGuest = ou.role === ResourceUserRole.Guest

        rights.canDeleteOffice = (ou.role === ResourceUserRole.Owner || ou.role === ResourceUserRole.Administrator) && (accountWithUsers.offices.length || 0) > 1
      }
    } else {
      const o = accountWithUsers.offices?.filter(
        (ox) => !!ox.users?.find((u) => u.id === me?.id && (u.role === ResourceUserRole.Owner || u.role === ResourceUserRole.Administrator || u.role === ResourceUserRole.Contributor))
      )
      if (o?.length) rights.canCreateProject = true
    }

    // console.log('rights', rights);
    return rights
  }, [accountWithUsers, me, officeId, projectId])
}

export const usersAtom = atom<User[]>({
  key: 'usersAtom',
  default: []
})

export interface UseUsersHook {
  users: User[]
  getUser: UseUsersHookGetUserFn
}
export interface UseUsersHookGetUserFn {
  (id: string | string[]): Promise<User[]>
}

export const useUsers = (): UseUsersHook => {
  const [users, setUsers] = useRecoilState(usersAtom)

  const getUser = useCallback(
    (id: string | string[]): Promise<User[]> => {
      const ids = Array.isArray(id) ? id : [id]

      const readyUsers = users.filter((u) => ids.indexOf(u.id) >= 0)
      const pendingUserIds = ids.filter((id) => !readyUsers.find((u) => u.id === id)).map((id) => id)

      // If no pending users, we are done
      if (pendingUserIds.length <= 0) return Promise.resolve(readyUsers)

      return getUsers({ ids: pendingUserIds }).then((users) => {
        setUsers((current) => {
          const newUsers = [...current]
          users.forEach((u) => {
            const idx = newUsers.findIndex((nu) => nu.id === u.id)
            if (idx >= 0) newUsers[idx] = u
            else newUsers.push(u)
          })
          return newUsers
        })
        return [...readyUsers, ...users]
      })
    },
    [users, setUsers]
  )

  return {
    users,
    getUser
  }
}
