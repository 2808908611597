import React, { useEffect } from 'react'
import { login } from 'services/src/aad'
import { Loading } from '../loading'

export const Login: React.FC = () => {
  useEffect(() => {
    login('/dashboard').then(() => {})
  }, [])
  return <Loading />
}
