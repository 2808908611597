import React, { useEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js'

const history = createBrowserHistory()

export interface UseTelemetryHook {
  telemetry?: ApplicationInsights
  plugin?: ReactPlugin
}

export const useTelemetry = (): UseTelemetryHook => {
  const [appInsights, setAppInsights] = useState<{
    telemetry: ApplicationInsights
    plugin: ReactPlugin
  }>()

  useEffect(() => {
    setAppInsights((current) => {
      if (current) return current

      const plugin = new ReactPlugin()
      const telemetry = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
          extensions: [plugin],
          extensionConfig: {
            [plugin.identifier]: { history }
          }
        }
      })
      telemetry.loadAppInsights()

      return { telemetry, plugin }
    })
  }, [history, setAppInsights])

  return appInsights || {}
}

export const TelemetryProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { plugin } = useTelemetry()
  return !plugin ? null : <AppInsightsContext.Provider value={plugin}>{children}</AppInsightsContext.Provider>
}
