import React from 'react'
import ReactPhoneInput, { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.scss'

export type PhoneInputProps = ReactPhoneInputProps

const PhoneInput: React.FC<PhoneInputProps> = ({ disabled, ...rest }) => (
  <div className={`ui-phone-input${disabled ? ' ui-disabled' : ''}`}>
    <ReactPhoneInput
      disabled={disabled}
      disableCountryGuess
      onFocus={(e) => {
        if (e.currentTarget.parentElement) {
          const q = e.currentTarget.parentElement.querySelector('.selected-flag')
          if (q && q.getAttribute('tabindex')) q.attributes.removeNamedItem('tabindex')
        }
        if (disabled) e.currentTarget.blur()
      }}
      {...rest}
    />
  </div>
)
export default PhoneInput
