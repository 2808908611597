import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import { useMe, useRights, UserType } from 'services/src/state'
import { EducationLevel, User } from 'services/src/dto/account'
import { getDisciplineSkills, updateUser, useProjectSupport } from 'services/src/api'

import './style.scss'
import { Loading } from 'components/src/loading'

import logoLight from 'components/src/img/cc-light.png'
import logoDark from 'components/src/img/cc-dark.png'
import bgLight from 'components/src/img/bgLight.webp'
import bgDark from 'components/src/img/bgDark.webp'

import { useTranslation } from 'react-i18next'
import { useSaving } from 'components/src/saving'
import moment from 'moment'
import { useAlert } from 'components/src/alerts'
import { useTheme } from 'components/src/theme'
import { ExpertProjectExperience } from './ExpertProjectExperience'
import { ExpertIndustryCertifications } from './ExpertIndustryCertifications'
import { ExpertEducation } from './ExpertEducation'
import { ExpertProfile } from './ExpertProfile'
import { ExpertProfessionalExpertise } from './ExpertProfessionalExpertise'
import { NewExpertModel } from './model'
import { ExpertNextSteps } from './ExpertNextSteps'

export const Onboarding: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const [me, , setMe] = useMe()
  const rights = useRights()
  const { alertDanger } = useAlert()
  const [, setSaving] = useSaving()
  const { theme } = useTheme()

  const [ready, setReady] = useState(false)
  const [{ buildingCategories, loading: supportLoading }] = useProjectSupport()

  const [model, setModel] = useState<NewExpertModel>({} as NewExpertModel)

  useEffect(() => {
    if (url.endsWith('/onboarding')) history.replace(`${path}/profile`)
  }, [url, path, history])

  useEffect(() => {
    document.body.classList.add('no-scroll-all')
    return () => {
      document.body.classList.remove('no-scroll-all')
    }
  }, [])

  useEffect(() => {
    if (!me || supportLoading || !buildingCategories || model.id) return

    getDisciplineSkills(i18n.language)
      .then((disciplineSkills) => {
        setModel({
          ...(me as User as any),
          phone: '',
          extension: '',
          disciplineSkills,
          skills: disciplineSkills.find((x) => x.discipline === me.primaryDiscipline)?.skills.map((s) => ({ label: s })) || [],

          currentCategory: 1,
          categoryProjectTypes: buildingCategories.map((c) => ({
            category: c.category,
            projectTypes: c.projectTypes.map((pt) => ({
              ...pt,
              label: pt.type,
              selected: me.categoryExperiences?.find((x) => x.category === c.category)?.items.find((x) => x.projectTypeId === pt.id)
            }))
          })),

          educationLevelIdx:
            // eslint-disable-next-line no-nested-ternary
            me.educationLevel === EducationLevel.Associate
              ? 0
              : // eslint-disable-next-line no-nested-ternary
              me.educationLevel === EducationLevel.Bachelor
              ? 1
              : // eslint-disable-next-line no-nested-ternary
              me.educationLevel === EducationLevel.Graduate
              ? 2
              : me.educationLevel === EducationLevel.Doctoral
              ? 3
              : -1,
          industrySpecific: true,

          currentCertification: { name: '', number: '', expMonth: 0, expYear: 0 },
          certifications: [...(me.certifications || [])]
        })
      })
      .finally(() => setReady(true))
  }, [model.id, me, supportLoading, i18n, buildingCategories, setModel])

  const submit = useCallback(() => {
    if (!me) return

    setSaving(true)
    const expert: User = {
      ...me,

      businessPhones: model.phone
        ? [
            {
              number: model.phone,
              extension: model.extension
            }
          ]
        : undefined,

      mobilePhone: model.mobilePhone,

      primaryDiscipline: model.primaryDiscipline,
      primaryDisciplineSkills: [...model.skills.filter((x) => !!x.selected).map((x) => x.label)],
      yearsOfExperience: model.yearsOfExp?.id,
      availability: model.availability?.id,

      expertRole: model.expertRole,
      hourlyRate: model.hourlyRate,

      categoryExperiences: model.categoryProjectTypes.map((c) => ({
        category: c.category,
        items: c.projectTypes
          .filter((pt) => !!pt.selected)
          .map((pt) => ({
            projectTypeId: pt.id,
            type: pt.type
          }))
      })),

      educationLevel: model.educationLevel,
      schoolName: model.schoolName,
      gradYear: model.gradYear ? parseInt(model.gradYear, 10) : undefined,
      industrySpecificDegree: model.industrySpecific,

      certifications: model.certifications.map((c) => ({
        name: c.name,
        number: c.number,
        expires: moment({ year: c.expYear, month: c.expMonth - 1 }).toDate()
      }))
    }

    updateUser(null, null, expert, model.picture, 'complete')
      .then((me) => {
        setMe(me)
        history.push(`${path}/nextsteps`)
      })
      .catch((error) => {
        alertDanger({
          title: t('Onboarding.Expert.ExpertSavedTitle'),
          message: t('Onboarding.Expert.ExpertSaved'),
          error
        })
      })
      .finally(() => setSaving(false))
  }, [setSaving, me, model, alertDanger, t, history, path, setModel])

  const rightRef = useRef<HTMLDivElement>(null)

  if (!ready) return <Loading />

  return (
    <div className="onboarding">
      <div className="page-left" style={{ backgroundImage: `url("${theme === 'dark' ? bgDark : bgLight}")` }}>
        <img className="logo" alt="" src={theme === 'dark' ? logoLight : logoDark} />
      </div>
      <div className="page-right" ref={rightRef}>
        <Route path={`${path}/profile`}>
          <ExpertProfile
            model={model}
            onChangeModel={setModel}
            onNext={() => {
              history.push(rights?.userType === UserType.provider ? `${path}/project-experience` : `${path}/expertise`)
              rightRef.current?.scrollTo(0, 0)
            }} />
        </Route>

        <Route path={`${path}/expertise`}>
          <ExpertProfessionalExpertise
            model={model}
            onChangeModel={setModel}
            onNext={() => {
              history.push(`${path}/project-experience`)
              rightRef.current?.scrollTo(0, 0)
            }}
            onBack={() => {
              history.goBack()
              rightRef.current?.scrollTo(0, 0)
            }} />
        </Route>

        <Route path={`${path}/project-experience`}>
          <ExpertProjectExperience
            model={model}
            onChangeModel={setModel}
            onNext={() => {
              history.push(`${path}/education`)
              rightRef.current?.scrollTo(0, 0)
            }}
            onBack={() => {
              history.goBack()
              rightRef.current?.scrollTo(0, 0)
            }} />
        </Route>

        <Route path={`${path}/education`}>
          <ExpertEducation
            model={model}
            onChangeModel={setModel}
            onNext={() => {
              history.push(`${path}/certifications`)
              rightRef.current?.scrollTo(0, 0)
            }}
            onBack={() => {
              history.goBack()
              rightRef.current?.scrollTo(0, 0)
            }} />
        </Route>

        <Route path={`${path}/certifications`}>
          <ExpertIndustryCertifications
            model={model}
            onChangeModel={setModel}
            onNext={() => {
              submit()
              rightRef.current?.scrollTo(0, 0)
            }}
            onBack={() => {
              history.goBack()
              rightRef.current?.scrollTo(0, 0)
            }} />
        </Route>

        <Route path={`${path}/nextsteps`}>
          <ExpertNextSteps model={model} />
        </Route>
      </div>
    </div>
  )
}
