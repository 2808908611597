/**
 * Set the document title.
 * @param {string} title - Optional document title.
 */
export const setTitle = (title?: string) => {
  document.title = `${title ? `${title} - ` : ''} Construction Check`
}

/** In client app? */
export const useIsClient = () => window.location.host.startsWith('client')

/** In provider app? */
export const useIsProvider = () => window.location.host.startsWith('provider')

/** In expert app? */
export const useIsExpert = () => window.location.host.startsWith('expert')

/**
 * Dashboard path helper
 * @param {[string]} accountId - Optional account id.
 * @param {[string]} rest - Optional path suffix.
 */
export const makeDashboardPath = (accountId?: string, rest?: string) => `/dashboard${!accountId || useIsExpert() ? '' : `/${accountId}`}${rest ? `/${rest}` : ''}`
