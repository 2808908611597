import React, { useEffect, useMemo } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import './style.scss'

export type DropzoneProps = DropzoneOptions & {
  acceptLabel?: string | JSX.Element
  rejectLabel?: string | JSX.Element
  idleLabel?: string | JSX.Element
  renderPreview?: () => JSX.Element | null
  showFileDialog?: boolean
  style?: React.CSSProperties
  variant?: 'round'
}

export const Dropzone: React.FC<React.PropsWithChildren<DropzoneProps>> = ({ acceptLabel, rejectLabel, idleLabel, renderPreview, showFileDialog, style, variant, children, ...rest }) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone(rest)

  useEffect(() => {
    if (showFileDialog === true) open()
  }, [showFileDialog])

  const zoneState = useMemo(() => {
    if (isDragAccept && acceptLabel) return { active: true, label: acceptLabel }
    if (isDragReject && rejectLabel) return { active: false, label: rejectLabel }
    return { active: isDragActive, label: idleLabel || t('General.DropFile') }
  }, [isDragAccept, acceptLabel, isDragReject, rejectLabel, isDragActive, idleLabel, t])

  return (
    <div {...getRootProps()} tabIndex={-1}>
      <input {...getInputProps()} />
      <div className={`ui-dropzone${zoneState.active ? ' active' : ''}${isDragAccept ? ' accept' : ''}${isDragReject ? ' reject' : ''}${variant ? ` ui-dropzone-${variant}` : ''}`} style={style}>
        {children}
        {React.Children.count(children) <= 0 && (
          <div className="ui-text-center" style={{ width: '100%', height: '100%', position: 'relative' }}>
            {(() => {
              const p = renderPreview ? renderPreview() : null
              if (!p)
                return (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%'
                    }}
                  >
                    {zoneState.label || ''}
                  </div>
                )
              return p
            })()}
          </div>
        )}
      </div>
    </div>
  )
}
