import { useCallback, useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { EventType, PublicClientApplication, IPublicClientApplication, AuthenticationResult, AccountInfo, Configuration } from '@azure/msal-browser'
import axios from 'axios'

/** The AAD configuration. */
export interface AadConfig {
  configuration: Configuration
  scopes: string[]
}

/** The AAD configuration. */
let theAadConfig: AadConfig
export const aadConfig = () => theAadConfig

/** The public client application. */
let thePsa: IPublicClientApplication
export const psa = () => thePsa

let currentAccessToken: string | null = null
let currentIdToken: string | null = null
let acquiredAt: number = 0

const SESSION_TIMEOUT = 15 * 60 * 1000 // 15 min
const SESSION_NEAR_END = 2 * 60 * 1000 // 2 min

/**
 * Initialize the public client application.
 * @param config - The configuration.
 */
export const initialize = (config: AadConfig) => {
  theAadConfig = { ...config }
  thePsa = new PublicClientApplication(theAadConfig.configuration)

  // Handle PSA events
  thePsa.addEventCallback((e: any) => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV !== 'production') console.log(e)

    const { eventType } = e

    if (eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      const { accessToken, idToken, account } = e.payload as AuthenticationResult

      currentAccessToken = accessToken
      if (!currentAccessToken) {
        delete axios.defaults.headers.common.Authorization
        thePsa.setActiveAccount(null)
        return
      }

      currentIdToken = idToken
      thePsa.setActiveAccount(account)

      axios.defaults.headers.common.Authorization = `Bearer ${currentAccessToken}`

      acquiredAt = Date.now()
    } else if (e.eventType === EventType.LOGOUT_SUCCESS || e.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      currentAccessToken = null
      currentIdToken = null
      acquiredAt = 0

      delete axios.defaults.headers.common.Authorization
      thePsa.setActiveAccount(null)

      sessionStorage.clear()
    }
  })
}

/**
 * Redirect to login.
 * @param {string} redirect - Optional redirect to.
 */
export const login = (redirect?: string): Promise<void> => {
  sessionStorage.clear()

  const fallback = `${process.env.REACT_APP_CC_SITE}`

  return thePsa.loginRedirect({
    scopes: theAadConfig.scopes,
    redirectStartPage: redirect || window.location.href,
    prompt: 'login',
    state: JSON.stringify({
      theme: localStorage.getItem('theme') || 'light',
      fallback
    })
  })
}

/**
 * End session.
 * @param {string} redirect - Optional redirect to.
 */
export const logout = (redirect?: string): Promise<void> => {
  const r = redirect || '/'
  localStorage.setItem('logoutRedirect', r)

  sessionStorage.clear()

  if (!currentIdToken) {
    window.location.href = r
    return Promise.resolve()
  }

  setTimeout(() => {
    window.location.href = r
  })

  return thePsa.logoutRedirect({
    idTokenHint: currentIdToken,
    postLogoutRedirectUri: theAadConfig.configuration.auth.redirectUri,
    state: JSON.stringify({ theme: localStorage.getItem('theme') || 'light' })
  })
}

/**
 * Start the change password process.
 * @param {string} redirect - Optional redirect to.
 */
export const changePassword = (redirect?: string): Promise<void> => {
  let policy = 'B2C_1A_ClientPasswordReset'
  if (window.location.host.startsWith('expert')) policy = 'B2C_1A_ExpertPasswordReset'
  else if (window.location.host.startsWith('provider')) policy = 'B2C_1A_ProviderPasswordReset'

  return thePsa.acquireTokenRedirect({
    authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_TENANT}/${policy}`,
    scopes: theAadConfig.scopes,
    redirectStartPage: redirect || window.location.href,
    state: JSON.stringify({ theme: localStorage.getItem('theme') || 'light' })
  })
}

let getAccessTokenPromise: Promise<string | void> | undefined
/** Get the access token. */
export const getAccessToken = (): Promise<string | void> => {
  if (getAccessTokenPromise) return getAccessTokenPromise

  getAccessTokenPromise = new Promise<string | void>((resolve) => {
    const account = thePsa.getActiveAccount() || thePsa.getAllAccounts()[0]
    if (!account) {
      login().then(resolve)
      return
    }

    thePsa
      .acquireTokenSilent({
        account,
        scopes: theAadConfig.scopes
      })
      .then((response) => {
        const { accessToken } = response
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        return accessToken
      })
      .then((accessToken) => resolve(accessToken))
      .catch(() => {
        login().then(resolve)
      })
      .finally(() => {
        getAccessTokenPromise = undefined
      })
  })

  return getAccessTokenPromise
}

/** Msal account hook. */
export const useMsalAccount = (): AccountInfo | null => {
  const { accounts } = useMsal()
  return useAccount(accounts[0] || null)
}

/** Account name hook. */
export const useMsalName = (): { givenName: string; familyName: string } => {
  const account = useMsalAccount()
  if (!account || !account.idTokenClaims) return { givenName: '', familyName: '' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { given_name, family_name } = account.idTokenClaims as any
  return { givenName: given_name, familyName: family_name }
}

/** Account email hook. */
export const useMsalEmail = (): string => {
  const account = useMsalAccount()
  if (!account || !account.idTokenClaims) return ''
  const { email, emails } = account.idTokenClaims as any
  return email || (emails && emails.length ? emails[0] : '')
}

/** Account email hook. */
export const useMsalAccessToken = (): string | null => currentAccessToken

export const useSessionTimeout = () => {
  const [isNearEnd, setIsNearEnd] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  const handleInteraction = useCallback(() => {
    if (acquiredAt <= 0) return

    acquiredAt = Date.now()
    setIsNearEnd(false)
  }, [])

  useEffect(() => {
    window.document.body.addEventListener('mousemove', handleInteraction)
    window.document.body.addEventListener('scroll', handleInteraction)
    window.document.body.addEventListener('keydown', handleInteraction)
    window.document.body.addEventListener('click', handleInteraction)
    window.document.body.addEventListener('touchstart', handleInteraction)

    return () => {
      window.document.body.removeEventListener('mousemove', handleInteraction)
      window.document.body.removeEventListener('scroll', handleInteraction)
      window.document.body.removeEventListener('keydown', handleInteraction)
      window.document.body.removeEventListener('click', handleInteraction)
      window.document.body.removeEventListener('touchstart', handleInteraction)
    }
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return () => {}

    const i = setInterval(() => {
      if (acquiredAt <= 0) return

      if (Date.now() - acquiredAt >= SESSION_TIMEOUT - SESSION_NEAR_END) {
        setIsNearEnd(true)
      }
      if (Date.now() - acquiredAt > SESSION_TIMEOUT) {
        setIsEnd(true)
      }
    }, 1000)

    return () => {
      clearInterval(i)
    }
  }, [])

  return { isNearEnd, isEnd }
}
