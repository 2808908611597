import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

export const themeState = atom<string>({
  key: 'theme',
  default: localStorage.getItem('theme') || 'light'
})

export const useTheme = () => {
  const [current, setCurrent] = useRecoilState(themeState)

  const setTheme = useCallback(
    (theme: string) => {
      setCurrent(theme)
      localStorage.setItem('theme', theme)

      document.body.classList.remove('theme-light', 'theme-dark')
      document.body.classList.add(`theme-${theme}`)

      window.dispatchEvent(new Event('themeChanged'))
    },
    [setCurrent]
  )

  return {
    theme: current,
    setTheme
  }
}
