import React from 'react'
import { CurrencyInput, CurrencyInputProps } from '../currencyInput'

export type CurrencyInputFieldProps = CurrencyInputProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const CurrencyInputField: React.FC<CurrencyInputFieldProps> = ({ name, label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <CurrencyInput name={name} {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
