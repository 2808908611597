import React from 'react'
import './style.scss'
import LoadingSvg from '../svg/Loading'

export const Loading: React.FC<{
  variant?: 'screen' | 'parent' | 'static'
  size?: 'sm' | 'md' | 'lg'
  className?: string
  style?: React.CSSProperties
}> = ({ variant, size, className, style }) => {
  let position = 'fixed'
  if (variant === 'parent') position = 'absolute'
  else if (variant === 'static') position = 'static'

  return (
    <div
      className={`ui-loading ui-loading-${size || 'lg'}${className ? ` ${className}` : ''}`}
      style={{
        ...style,
        position: position as any,
        left: variant === 'parent' ? 0 : undefined
      }}
    >
      <LoadingSvg />
    </div>
  )
}
