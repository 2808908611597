import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { QueryParamProvider } from 'use-query-params'

import { psa } from 'services/src/aad'
import { apiInterceptor } from 'services/src/api'

import { TelemetryProvider } from 'services/src/telemetry'

import 'style/main.scss'

import { Alerts, AlertsDrawer } from 'components/src/alerts'
import { Loading } from 'components/src/loading'
import NetworkError from 'components/src/networkError'

import Routes from './Routes'

const App: React.FC = () => {
  const [networkError, setNetworkError] = useState()

  const handleNetworkSuccess = useCallback(() => {
    if (networkError) {
      setNetworkError(undefined)
      window.dispatchEvent(new Event('networkRestored'))
    }
  }, [networkError, setNetworkError])

  const handleNetworkError = useCallback(
    (err: any) => {
      setNetworkError(err.message === 'Network Error' ? err : undefined)
      window.dispatchEvent(new Event('networkError'))
    },
    [setNetworkError]
  )

  useEffect(() => apiInterceptor(handleNetworkSuccess, handleNetworkError), [handleNetworkSuccess, handleNetworkError])

  return (
    <Suspense fallback={<Loading />}>
      <Alerts />
      <AlertsDrawer />
      <Router basename={process.env.PUBLIC_URL}>
        <TelemetryProvider>
          <QueryParamProvider>
            <MsalProvider instance={psa()}>
              <Routes />
            </MsalProvider>
          </QueryParamProvider>
        </TelemetryProvider>
      </Router>
      {networkError && <NetworkError />}
    </Suspense>
  )
}
export default App
