import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import CurrencyInputCmp, { CurrencyInputProps as CurrencyInputCmpProps } from 'react-currency-input-field'

export type CurrencyInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  Omit<CurrencyInputCmpProps, 'onChange'> & {
    amount?: string
    digits?: 0 | 1 | 2
    onChange: (amount: number | undefined) => void
  }

// eslint-disable-next-line react/display-name
export const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(({ amount, digits = 0, onChange, ...rest }, ref) => {
  const [value, setValue] = useState(amount)

  useEffect(() => {
    setValue(amount)
  }, [amount])

  return (
    <CurrencyInputCmp
      ref={ref}
      value={value}
      defaultValue={amount}
      decimalScale={digits || 2}
      decimalsLimit={digits || 2}
      allowNegativeValue={false}
      onValueChange={(value) => {
        setValue(value)
        onChange(value ? parseFloat(value) : undefined)
      }}
      {...rest}
    />
  )
})
