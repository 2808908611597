import React from 'react'
import PhoneInput, { PhoneInputProps } from '../phoneInput'

export type PhoneInputFieldProps = PhoneInputProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const PhoneInputField: React.FC<PhoneInputFieldProps> = ({ label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label>{label}</label>}
    <PhoneInput {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
