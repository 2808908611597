import axios from 'axios'
import moment from 'moment'
import { getAccessToken } from '../aad'
import { Account, AccountWithUsers, Client, Office, ResourceUser, ResourceUserRole, User } from '../dto/account'
import { Project } from '../dto/project'
import { CreateInvitation, Invitation } from '../dto/invitation'
import { Transaction } from '../dto/transaction'
import apiProxy from './apiProxy'
import { Patch } from '../dto/common'

const addUserToFormData = (accountId: string, user: User, formData: FormData) => {
  formData.append('accountId', accountId)
  formData.append('id', user.id)
  formData.append('status', user.status)
  formData.append('givenName', user.givenName)
  formData.append('familyName', user.familyName)
  user.emails?.forEach((e, idx) => {
    formData.append(`emails[${idx}].email`, e.email)
    if (e.label) formData.append(`emails[${idx}].label`, e.label)
  })

  formData.append('jobTitle', user.jobTitle || '')
  user.businessPhones?.forEach((p, idx) => {
    if (!p.number) return
    formData.append(`businessPhones[${idx}].number`, p.number)
    if (p.extension) formData.append(`businessPhones[${idx}].extension`, p.extension)
    if (p.label) formData.append(`businessPhones[${idx}].label`, p.label)
  })
  if (user.mobilePhone) formData.append('mobilePhone', user.mobilePhone)

  formData.append('hourlyRate', user.hourlyRate?.toFixed(2) || '0')
  formData.append('primaryDiscipline', user.primaryDiscipline || '')
  user.primaryDisciplineSkills?.forEach((s, idx) => {
    formData.append(`primaryDisciplineSkills[${idx}]`, s)
  })
  formData.append('yearsOfExperience', user.yearsOfExperience || '')
  formData.append('availability', user.availability || '')
  formData.append('expertRole', user.expertRole || '')
  formData.append('expertType', user.expertType || '')
  user.categoryExperiences?.forEach((x, idx) => {
    formData.append(`categoryExperiences[${idx}].category`, x.category.toString())
    x.items.forEach((i, itemIdx) => {
      formData.append(`categoryExperiences[${idx}].items[${itemIdx}].projectTypeId`, i.projectTypeId)
      formData.append(`categoryExperiences[${idx}].items[${itemIdx}].type`, i.type)
    })
  })
  formData.append('educationLevel', user.educationLevel || '')
  formData.append('schoolName', user.schoolName || '')
  formData.append('gradYear', user.gradYear?.toFixed(0) || '')
  formData.append('industrySpecificDegree', user.industrySpecificDegree === true ? 'true' : 'false')
  user.certifications?.forEach((c, idx) => {
    formData.append(`certifications[${idx}].name`, c.name)
    formData.append(`certifications[${idx}].number`, c.number || '')
    formData.append(`certifications[${idx}].expires`, c.expires ? moment(c.expires).format() : '')
  })

  if (user.personalAddress) {
    const a = user.personalAddress as any
    Object.keys(a).forEach((key) => {
      if (a[key]) formData.append(`personalAddress.${key}`, a[key])
    })
  }
}

/** Create an account. */
export const createAccount = async (account: Account, user: User, logo?: File, picture?: File): Promise<{ account: Account; user: User }> => {
  await getAccessToken()

  let formData = new FormData()
  formData.append('id', account.id)
  formData.append('companyName', account.companyName)
  formData.append('companyType', account.companyType)
  formData.append('numberOfEmployees', account.numberOfEmployees || '')
  if (account.offices?.length) {
    const a = account.offices[0].address as any
    Object.keys(a).forEach((key) => {
      if (a[key]) formData.append(`companyAddress.${key}`, a[key])
    })
  }

  if (logo) {
    const f = Object.assign(logo)
    delete f.preview
    formData.append('logo', f)
  }

  const { data: a } = await axios.post<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })

  formData = new FormData()
  addUserToFormData(a.id, user, formData)

  if (picture) {
    const f = Object.assign(picture)
    delete f.preview
    formData.append('picture', f)
  }

  const { data: u } = await axios.put<User>(`${process.env.REACT_APP_ACCOUNT_API}/users/me?onboard=complete`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })

  return { account: a, user: u }
}

/** Update an account. */
export const updateAccount = async (account: Account, logo?: File): Promise<Account> => {
  await getAccessToken()

  const formData = new FormData()
  formData.append('id', account.id)
  formData.append('companyName', account.companyName)
  formData.append('companyType', account.companyType)
  formData.append('numberOfEmployees', account.numberOfEmployees || '')

  if (logo) {
    const f = Object.assign(logo)
    delete f.preview
    formData.append('logo', f)
  }

  const { data } = await axios.put<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${account.id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  })

  return data
}

/** Upload account logo. */
export const uploadAccountLogo = async (accountId: string, logo: File): Promise<Account> => {
  await getAccessToken()

  const formData = new FormData()
  const f = Object.assign(logo)
  formData.append('logo', f)

  const { data: a } = await axios.post<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/logo`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })

  return a
}

/** Update a user */
export const updateUser = async (accountId: string | null, id: string | null, user: User, picture?: File, onboard?: string): Promise<User> => {
  await getAccessToken()

  const formData = new FormData()
  addUserToFormData(accountId || '', user, formData)

  if (picture) {
    const f = Object.assign(picture)
    delete f.preview
    formData.append('picture', f, picture.name)
  }

  const { data } = await axios.put<User>(`${process.env.REACT_APP_ACCOUNT_API}/users/${id || 'me'}${onboard ? `?onboard=${onboard}` : ''}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  return data
}

/** Fetch a account. */
export const getAccountById = apiProxy('getAccountById', async (id: string): Promise<Account> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${id}`)
  return data
})

/** Fetch all user accounts. */
export const getAccounts = apiProxy('getAccounts', async (): Promise<Account[]> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts`)
  return data
})

/** Fetch all client account by the provider id. */
export const getAccountsByAccount = apiProxy(
  'getAccountsByAccount',
  async (accountId: string, offset: number, limit: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; accounts: Account[] }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset}`, `limit=${limit}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/list${q.length ? `?${q.join('&')}` : ''}`)
    return data
  }
)

/** Get account users. */
export const getAccountUsers = apiProxy('getAccountUsers', async (accountId: string, projectId?: string): Promise<AccountWithUsers> => {
  await getAccessToken()

  const q: string[] = []
  if (projectId) q.push(`projectId=${projectId}`)

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users${q.length ? `?${q.join('&')}` : ''}`)
  return data
})

/** Get account counts. */
export const getAccountCounts = apiProxy('getAccountCounts', async (accountId: string): Promise<{ [key: string]: number }> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/counts`)
  return data
})

/** Link account users. */
export const linkAccountUser = apiProxy('linkAccountUser', async (accountId: string, users: ResourceUser[]): Promise<void> => {
  await getAccessToken()

  const patch: Patch[] = [
    {
      propertyName: 'Users',
      propertyValue: users
    }
  ]

  await axios.patch(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}`, patch)
})

/** Delete account users. */
export const deleteAccountUser = apiProxy('deleteAccountUser', async (accountId: string, userId: string): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users/${userId}`)
})

/** Delete account users. */
export const updateAccountUserRole = apiProxy('updateAccountUserRole', async (accountId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken()

  const q: string[] = [`role=${role}`]
  await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users/${userId}?${q.join('&')}`)
})

/** Fetch account offices. */
export const getOffices = apiProxy(
  'getOffices',
  async (accountId: string, offset: number, limit: number, sort?: string, sortDir?: string, filter?: string): Promise<{ offices: Office[]; total: number }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset}`, `limit=${limit}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices${q.length ? `?${q.join('&')}` : ''}`)
    return data
  }
)

/** Fetch a single office. */
export const getOffice = apiProxy('getOffice', async (accountId: string, officeId: string): Promise<Office> => {
  await getAccessToken()

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/offices/${officeId}`)
  return data
})

/** Create/update account office. */
export const upsertOffice = apiProxy('upsertOffice', async (accountId: string, office: Office): Promise<Office> => {
  await getAccessToken()
  const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices`, office)
  return data
})

/** Delete account office. */
export const deleteOffice = apiProxy('deleteOffice', async (accountId: string, officeId: string): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}`)
})

/** Delete account office user. */
export const deleteOfficeUser = apiProxy('deleteOfficeUser', async (accountId: string, officeId: string, userId: string): Promise<void> => {
  await getAccessToken()
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}/users/${userId}`)
})

/** Delete account office. */
export const updateOfficeUserRole = apiProxy('updateOfficeUserRole', async (accountId: string, officeId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken()
  await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}/users/${userId}?role=${role}`)
})

/** Fetch account clients. */
export const getClients = apiProxy('getClients', async (accountId: string): Promise<Client[]> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/clients`)
  return data
})

/** Fetch self. */
export const getMe = apiProxy('getMe', async (providerId?: string): Promise<User> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users/me${providerId ? `?providerId=${providerId}` : ''}`)

  data.providerId = providerId
  data.createdAt = data.createdAt ? new Date(data.createdAt) : new Date()
  return data
})

export interface GetUsersParams {
  [key: string]: any
  ids?: string[]
  lat?: number
  lon?: number
  accountId?: string
  givenName?: string
  familyName?: string
  email?: string
  disciplines?: string[]
  category?: number
  projectTypeId?: string
  search?: string
  country?: string
  countrySubdivision?: string
  municipality?: string
  providerOnly?: boolean
  status?: string | undefined
  expertType?: string
  offset?: number
  limit?: number
}
export const getUsers = apiProxy('getUsers', async (params: GetUsersParams): Promise<User[]> => {
  await getAccessToken()

  const qs: string[] = []
  Object.keys(params).forEach((k) => {
    if (params[k] == null || params[k] === undefined) return

    if (k === 'ids') {
      qs.push(`${k}=${encodeURIComponent(params[k]!.join(','))}`)
      return
    }

    if (k === 'lat' || k === 'lon' || k === 'offset' || k === 'limit' || k === 'category') {
      qs.push(`${k}=${encodeURIComponent(params[k]!.toString())}`)
      return
    }
    if (k === 'disciplines') {
      qs.push(`${k}=${encodeURIComponent((params[k] as string[]).join(','))}`)
      return
    }
    qs.push(`${k}=${encodeURIComponent(params[k] as string)}`)
  })

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users?${qs.join('&')}`)
  return data
})

/** Fetch provider users. */
export const getProviderUsers = apiProxy('getProviderUsers', async (id: string): Promise<User[]> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users/${id}`)
  return data
})

/** Fetch all company types. */
export const getCompanyTypes = apiProxy('getCompanyTypes', async (): Promise<{ [key: string]: string }> => {
  await getAccessToken()
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/support/companytypes`)
  return data
})

/** Create an invitation. */
export const createInvitation = apiProxy('createInvitation', async (accountId: string, create: CreateInvitation): Promise<Invitation> => {
  await getAccessToken()
  const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations`, create)
  return data
})

/** Verify the invitation. */
export const verifyInvitation = apiProxy('verifyInvitation', async (key: string): Promise<Invitation> => {
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/invitations?key=${encodeURIComponent(key)}`)
  return data
})

/** Complete the invitation. */
export const completeInvitation = apiProxy(
  'completeInvitation',
  async (
    key: string
  ): Promise<{
    account: Account
    project?: Project
  }> => {
    await getAccessToken()
    const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/invitations?key=${encodeURIComponent(key)}`)
    return data
  }
)

/** Delete an invitation. */
export const deleteInvitation = apiProxy('deleteInvitation', async (accountId: string, invitationId: string): Promise<Invitation> => {
  await getAccessToken()
  const { data } = await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations/${invitationId}`)
  return data
})

/** Resend an invitation. */
export const resendInvitation = apiProxy('resendInvitation', async (accountId: string, invitationId: string): Promise<Invitation> => {
  await getAccessToken()
  const { data } = await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations/${invitationId}/resend`)
  return data
})

/** Lookup a school. */
export const schoolLookup = apiProxy('schoolLookup', async (name: string) => {
  const { data } = await axios.get(
    `https://api.data.gov/ed/collegescorecard/v1/schools.json?fields=id,school.name&school.name=${encodeURIComponent(name)}&api_key=KWCevTbRUDsZtdQi5oSi5qbyENY8dWmdO68ihA5n`
  )
  return data
})

/** Fetch all transactions. */
export const getAllTransactions = apiProxy(
  'getAllTransactions',
  async (offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions?${q.join('&')}`)
    return data
  }
)

export interface TransactionStatItem {
  total?: number
  amount: number
  credit: number
  debit: number
}
export type TransactionStats = TransactionStatItem & {
  threeMonth: (TransactionStatItem & { month: number; year: number })[]
}

export interface GetTransactionStatsParams {
  accountId?: string
  userId?: string
  start?: number
  end?: number
  sort?: string
  sortDir?: string
  filter?: string
}
/** Fetch transaction statistics. */
export const getTransactionStats = apiProxy('getTransactionStats', async ({ accountId, userId, start, end, sort, sortDir, filter }: GetTransactionStatsParams): Promise<TransactionStats> => {
  await getAccessToken()

  const q: string[] = [`start=${start || moment.utc().startOf('y').valueOf()}`, `end=${end || moment.utc().endOf('y').valueOf()}`]
  if (sort) {
    q.push(`sort=${encodeURIComponent(sort)}`)
    q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
  }
  if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

  if (accountId) q.push(`accountId=${accountId}`)
  else if (userId) q.push(`userId=${userId}`)

  const { data } = await axios.get<TransactionStats>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/statistics?${q.join('&')}`)
  return data
})

/** Fetch client transactions. */
export const getClientTransactions = apiProxy(
  'getClientTransactions',
  async (clientId: string, offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/clients/${clientId}?${q.join('&')}`)
    return data
  }
)

/** Fetch user transactions. */
export const getUserTransactions = apiProxy(
  'getUserTransactions',
  async (userId: string, offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken()

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/users/${userId}?${q.join('&')}`)
    return data
  }
)
