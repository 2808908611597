import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

export type TextFieldProps = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> & {
  label?: string | JSX.Element
  error?: string | JSX.Element
  onChange: (value: string) => void
  forwardRef?: React.ForwardedRef<HTMLInputElement>
}

const TheTextField: React.FC<TextFieldProps> = ({ onChange, label, error, forwardRef, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label htmlFor={rest.id}>{label}</label>}
    <input onChange={(e) => onChange(e.currentTarget.value)} autoComplete="new" ref={forwardRef} {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)

export const TextField = React.forwardRef<any, TextFieldProps>((props, ref) => <TheTextField forwardRef={ref} {...props} />)
