import moment from 'moment'

export const i84nFormat = (value: any, format?: string, lng?: string, options?: any) => {
  if (format === 'currency') {
    if (options) {
      return new Intl.NumberFormat(lng, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: options.minDigits,
        maximumFractionDigits: options.maxDigits
      }).format(options.value)
    }

    return new Intl.NumberFormat(lng, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: value.minDigits,
      maximumFractionDigits: value.maxDigits
    }).format(value)
  }
  if (format === 'alpha') {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    return letters.charAt(value)
  }
  if (format === 'roman') {
    if (value === 1) return 'I'
    if (value === 2) return 'II'
    if (value === 3) return 'III'
    if (value === 4) return 'IV'
    if (value === 5) return 'V'
  }
  if (format === 'uppercase') return value.toUpperCase()
  if (format === 'lowercase') return value.toLowerCase()
  if (format === 'size') {
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    const g = Math.floor(Math.log10(value) / Math.log10(1024))
    return `${(value / 1024 ** g).toFixed(1)} ${units[g]}`
  }
  if (value instanceof Date) {
    return moment(value).format(format)
  }

  return value
}
