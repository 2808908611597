/** Width where we consider display to be a desktop. */
export const DESKTOP_SIZE = 992

/** Main Nav open width */
export const MAIN_NAV_OPEN_WIDTH = 80

/** Project phase minimum number days. */
export const PROJECT_PHASE_MIN_DAYS = 21

/** Alert timeout in ms. */
export const ALERT_TIMEOUT = 7000

/** Negotiated fee different max. */
export const NEGOTIATION_LIMIT = 15
