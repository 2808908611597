export enum DisciplineKey {
  ProjectPrincipal = 'ProjectPrincipal',
  ProjectManagement = 'ProjectManagement',
  QaQc = 'QaQc',
  General = 'General',
  Mechanical = 'Mechanical',
  Electrical = 'Electrical',
  SiteCivil = 'SiteCivil',
  TechnicalAssistant = 'TechnicalAssistant',
  SubjectMaterExpert = 'SubjectMaterExpert'
}

export enum EstimateTypeKey {
  IndependentCostEstimate = 'IndependentCostEstimate',
  IndependentCostReview = 'IndependentCostReview'
}

export enum FeeType {
  Generated = 'Generated',
  Manual = 'Manual',
  Negotiated = 'Negotiated'
}

export enum PhaseTypeKey {
  Concept = 'Concept',
  Schematic = 'Schematic',
  DesignDoc = 'DesignDoc',
  ConstructionDoc = 'ConstructionDoc',
  Proposal = 'Proposal'
}

export enum ProjectStatus {
  Draft = 'Draft',
  New = 'New',
  Submitted = 'Submitted',
  Accepted = 'Accepted',
  FeeSelected = 'FeeSelected',
  FeeReady = 'FeeReady',
  FeeCounter = 'FeeCounter',
  Approved = 'Approved',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Rejected = 'Rejected',
  Canceled = 'Canceled'
}

export enum ProjectTypeSource {
  Ciqs = 'Ciqs',
  Cmd = 'Cmd',
  User = 'User'
}

export interface ProjectType {
  id: string
  category: number
  subcategory: number
  type: string
  constructionCategory: string
  projectCategory: string
  source: ProjectTypeSource
}
