import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { RecoilRoot } from 'recoil'
import { LicenseManager } from 'ag-grid-enterprise'
import { BrowserCacheLocation } from '@azure/msal-browser'

import { initialize as initializeAad } from 'services/src/aad'
import App from './components/App'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`)

const r = localStorage.getItem('logoutRedirect')
if (r) {
  localStorage.removeItem('logoutRedirect')
  window.location.href = r
} else {
  initializeAad({
    configuration: {
      auth: {
        clientId: `${process.env.REACT_APP_EXPERT_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_TENANT}/B2C_1A_Expert`,
        knownAuthorities: [`${process.env.REACT_APP_AUTHORITY_DOMAIN}`],
        redirectUri: process.env.REACT_APP_EXPERT_REDIRECT_URI
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false
      },
      system: {}
    },
    scopes: ['openid', 'offline_access', 'profile', `${process.env.REACT_APP_SCOPE_READ}`, `${process.env.REACT_APP_SCOPE_WRITE}`]
  })

  document.body.classList.add(`theme-${localStorage.getItem('theme') || 'light'}`)

  // Render the component tree
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
