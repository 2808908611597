import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCity, faDraftingCompass, faExclamationTriangle, faUserChart } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import { Layout } from '../layout/public'

export const WrongApp: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Layout className="error-page">
      <div className="error-page-code">
        <FontAwesomeIcon icon={faExclamationTriangle} /> {t('General.Errors.WrongAppTitle')}
      </div>
      <div className="error-page-text ui-text-center">{t('General.Errors.WrongApp')}</div>

      <div className="ui-container buttons">
        <div className="ui-row">
          <div className="ui-col-12 ui-offset-lg-3 ui-col-lg-6">
            <div className="ui-flex ui-flex-column">
              <a
                href={`${process.env.REACT_APP_CC_CLIENT_SITE}/login`}
                className="ui-btn ui-btn-info ui-btn-solid ui-btn"
                style={{
                  height: 100,
                  width: '100%',
                  display: 'block',
                  lineHeight: 1.5,
                  paddingTop: 15,
                  marginBottom: 10
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faUserChart} size="2x" />
                </div>
                <div>{t('General.ClientSignIn')}</div>
              </a>

              <a
                href={`${process.env.REACT_APP_CC_EXPERT_SITE}/login`}
                className="ui-btn ui-btn-info ui-btn-solid ui-btn"
                style={{
                  height: 100,
                  width: '100%',
                  display: 'block',
                  lineHeight: 1.5,
                  paddingTop: 15,
                  marginBottom: 10
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faDraftingCompass} size="2x" />
                </div>
                <div>{t('General.ExpertSignIn')}</div>
              </a>

              <a
                href={`${process.env.REACT_APP_CC_PROVIDER_SITE}/login`}
                className="ui-btn ui-btn-info ui-btn-solid ui-btn"
                style={{
                  height: 100,
                  width: '100%',
                  display: 'block',
                  lineHeight: 1.5,
                  paddingTop: 15,
                  marginBottom: 10
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faCity} size="2x" />
                </div>
                <div>{t('General.ProviderSignIn')}</div>
              </a>
            </div>

            <br />
            <br />
            <div className="ui-text-center ui-text-lg">
              <a href={process.env.REACT_APP_CC_SITE} className="ui-info">
                <FontAwesomeIcon icon={faArrowLeft} /> {t('General.BackToCC')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
