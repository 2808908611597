import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'components/src/theme'

import './style.scss'
import logoDark from 'components/src/img/cc-dark-alt.png'
import logoLight from 'components/src/img/cc-light-alt.png'

export const Layout: React.FC<React.HTMLProps<HTMLDivElement> & {}> = ({ className, children }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const [pic, setPic] = useState<any>()
  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setPic(img)
    }
    img.src = `${process.env.PUBLIC_URL}/homePic.jpg`
  }, [])

  const [videoOpen, setVideoOpen] = useState(false)

  useEffect(() => {
    if (className) className.split(' ').forEach((c) => document.body.classList.add('public-layout', c.trim()))
    else document.body.classList.add('public-layout')
    return () => {
      if (className) className.split(' ').forEach((c) => document.body.classList.remove('public-layout', c.trim()))
      else document.body.classList.remove('public-layout')
    }
  }, [className])

  if (!pic) return null

  // noinspection JSUnresolvedLibraryURL
  return (
    <>
      <div className="public-layout-content">
        <div className="logo">
          <a href={process.env.REACT_APP_CC_SITE}>
            <img alt="Construction Check, Inc" src={theme === 'dark' ? logoLight : logoDark} />
          </a>
        </div>

        <div className="picture" style={{ backgroundImage: `url(${pic.src})` }}>
          <div className="overlay" />
          <div className="watch">
            <button className="ui-btn ui-btn-solid ui-btn-lg ui-btn-white" type="button" onClick={() => setVideoOpen(true)}>
              {t('General.WatchDemo')}
            </button>
          </div>
        </div>

        <div className="content">{children}</div>
      </div>

      {videoOpen && (
        <div className="public-video">
          <button type="button" onClick={() => setVideoOpen(false)} className="ui-btn ui-btn-secondary ui-btn-solid ui-btn-sm">
            CLOSE
          </button>

          <iframe
            src="https://player.vimeo.com/video/643638057?h=3e40e4108e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Construction Check"
          />
        </div>
      )}
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  )
}
