import { useCallback, useEffect, useState } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { getAccessToken, useMsalAccessToken } from '../aad'

import { ProjectContent, ProjectContentCategory } from '../dto/content'
import { DisciplineKey, Project } from '../dto/project'
import { Patch } from '../dto/common'

export type ProjectContentEx = ProjectContent & {
  discipline?: DisciplineKey
  disciplineById?: string
  classification?: DisciplineKey
  score?: number
  parent?: ProjectContentEx
  children: ProjectContentEx[]
  images?: {
    full?: string
    card?: string
    thumb?: string
  }
  pageIndex?: number
}

const projectFilesAtom = atom<ProjectContentEx[] | undefined>({
  key: 'projectFiles',
  default: undefined
})
type ProjectFilesCategoryAtomType = ProjectContentCategory | ProjectContentCategory[] | undefined
const projectFilesCategoryAtom = atom<ProjectFilesCategoryAtomType>({
  key: 'projectFilesCategory',
  default: undefined
})
const projectFilesFetchAtom = atom<boolean>({
  key: 'projectFilesFetch',
  default: false
})

export type UseProjectFilesHook = {
  allFiles?: ProjectContentEx[]
  setAllFiles: (valueOrUpdater: ((current?: ProjectContentEx[]) => ProjectContentEx[] | undefined) | ProjectContentEx[] | undefined) => void
  files?: ProjectContentEx[]
  category?: ProjectContentCategory | ProjectContentCategory[]
  inFetch: boolean
}

export const useProjectFiles = (category?: ProjectContentCategory | ProjectContentCategory[]): UseProjectFilesHook => {
  const [allFiles, doSetAllFiles] = useRecoilState(projectFilesAtom)
  const projectFilesFetch = useRecoilValue(projectFilesFetchAtom)
  const [files, setFiles] = useState<ProjectContentEx[]>()
  const accessToken = useMsalAccessToken()

  const setAllFiles = useCallback(
    (valueOrUpdater: ((current?: ProjectContentEx[]) => ProjectContentEx[] | undefined) | ProjectContentEx[] | undefined) => {
      if (!valueOrUpdater) {
        doSetAllFiles(valueOrUpdater)
        return
      }

      if (Array.isArray(valueOrUpdater)) {
        doSetAllFiles(
          valueOrUpdater?.map((f) => {
            f.discipline = (f?.metadata?.discipline || undefined) as DisciplineKey
            f.disciplineById = (f?.metadata?.by || undefined) as string
            f.classification = (f?.metadata?.classification || undefined) as DisciplineKey
            f.score = (f?.metadata?.score || undefined) as number
            return f
          })
        )
        return
      }

      doSetAllFiles((current) => {
        const files = valueOrUpdater(current)
        return files?.map((f) => {
          f.discipline = (f?.metadata?.discipline || undefined) as DisciplineKey
          f.disciplineById = (f?.metadata?.by || undefined) as string
          f.classification = (f?.metadata?.classification || undefined) as DisciplineKey
          f.score = (f?.metadata?.score || undefined) as number
          return f
        })
      })
    },
    [doSetAllFiles]
  )

  useEffect(() => {
    if (!allFiles) {
      setFiles(undefined)
      return
    }

    const content: ProjectContentEx[] = JSON.parse(JSON.stringify(allFiles))
    const result: ProjectContentEx[] = []

    const prepCategory = (category: ProjectContentCategory) => {
      content
        .filter((c) => !c.parentId && c.category === category)
        .sort((a, b) => a.originalName.localeCompare(b.originalName))
        .forEach((c) => {
          result.push(c)

          c.children = []

          if (c.explodeInfo?.pages) {
            c.explodeInfo.pages.forEach((expInfo) => {
              const page = content.find((x) => x.parentId === c.id && x.originalName === expInfo.pageName)
              // Was page found?
              if (!page) {
                c.children.push({ pageExplodeInfo: expInfo } as ProjectContentEx)
              }
            })
          }

          content
            .filter((c2) => c2.parentId === c.id)
            .forEach((c2, idx) => {
              c2.parent = c
              c.children.push(c2)

              if (c.explodeInfo?.pages) {
                c2.pageExplodeInfo = c.explodeInfo.pages.find((x) => x.pageName === c2.originalName)
                c2.pageIndex = idx
              }

              c2.children = []
              content
                .filter((c3) => c3.parentId === c2.id)
                .forEach((c3) => {
                  c3.parent = c2

                  if (c3.contentType.startsWith('image/')) {
                    if (!c2.images) c2.images = {}
                    if (c3.originalName.includes('-TH.')) c2.images.thumb = c3.url.includes('sv=') ? c3.url : `${c3.url}?access_token=${accessToken}`
                    else if (c3.originalName.includes('-MD.')) c2.images.card = c3.url.includes('sv=') ? c3.url : `${c3.url}?access_token=${accessToken}`
                    else c2.images.full = c3.url.includes('sv=') ? c3.url : `${c3.url}?access_token=${accessToken}`
                  }

                  c2.children.push(c3)
                })
            })

          c.children = c.children.sort((a, b) => {
            const ax = a.pageExplodeInfo?.pageNo || 0
            const bx = b.pageExplodeInfo?.pageNo || 0
            if (ax < bx) return -1
            return ax > bx ? 1 : 0
          })
        })
    }

    if (category) {
      if (Array.isArray(category)) category.forEach((cat) => prepCategory(cat))
      else prepCategory(category)
    }

    setFiles(result)
  }, [allFiles, setFiles, accessToken])

  return {
    allFiles,
    setAllFiles,
    files,
    category,
    inFetch: projectFilesFetch
  }
}

export type UseProjectFilesWsHook = {
  connection?: HubConnection
  category?: ProjectContentCategory | ProjectContentCategory[]
  allFiles?: ProjectContentEx[]
  files?: ProjectContentEx[]
  patch: (id: string, patch: Patch[]) => Promise<void>
  inFetch: boolean
}

export const useProjectFilesWs = (project: Project, phaseIds: string[], category: ProjectContentCategory | ProjectContentCategory[], estimateIds?: string[]): UseProjectFilesWsHook => {
  const [projectFilesCategory, setProjectFilesCategory] = useRecoilState(projectFilesCategoryAtom)
  useEffect(() => setProjectFilesCategory(category), [category])

  const [projectFilesFetch, setProjectFilesFetch] = useRecoilState(projectFilesFetchAtom)

  const { allFiles, setAllFiles, files } = useProjectFiles(projectFilesCategory)

  const [connection, setConnection] = useState<HubConnection>()

  useEffect(() => {
    if (project?.account?.id) {
      setAllFiles(undefined)
      setProjectFilesFetch(true)

      setTimeout(() => {
        setConnection((current) => {
          if (current && current.state !== HubConnectionState.Disconnected) return current

          // Create connection instance
          return new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_CONTENT_API}/project/content?aid=${project.account?.id}`, {
              accessTokenFactory: () => getAccessToken().then((r) => r as string)
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Warning)
            .build()
        })
      })
    }

    return () => {
      setConnection((current) => {
        if (current && current.state === HubConnectionState.Connected) {
          current.stop().then(() => {})
        }
        return undefined
      })
    }
  }, [project, phaseIds, setConnection, setAllFiles])

  useEffect(() => {
    // Make sure we are ready
    if (connection?.state === HubConnectionState.Connected) {
      setAllFiles(undefined)
      setProjectFilesFetch(true)
      connection.send('Fetch', project.id, phaseIds, estimateIds || [], Array.isArray(category) ? category : [category]).then(() => {})
    }
  }, [connection, projectFilesCategory, setAllFiles, setProjectFilesFetch])

  const handleConnected = useCallback(
    (connection: HubConnection) => {
      setAllFiles(undefined)
      setProjectFilesFetch(true)
      connection.send('Fetch', project.id, phaseIds, estimateIds || [], Array.isArray(category) ? category : [category]).then(() => {})
    },
    [project, phaseIds, category, estimateIds, setProjectFilesFetch]
  )

  const handleFetch = useCallback(
    (content: ProjectContentEx[]) => {
      setAllFiles((current) => {
        const newAllFiles: ProjectContentEx[] = JSON.parse(JSON.stringify(current || []))
        content.forEach((c) => {
          const idx = newAllFiles.findIndex((x) => x.id === c.id)
          if (idx >= 0) newAllFiles[idx] = c
          else newAllFiles.push(c)
        })
        return newAllFiles
      })
      setTimeout(() => setProjectFilesFetch(false))
    },
    [setAllFiles]
  )

  const handleNew = useCallback(
    (content: ProjectContentEx) => {
      setAllFiles((current) => {
        const newAllFiles: ProjectContentEx[] = JSON.parse(JSON.stringify(current || []))
        const idx = newAllFiles.findIndex((x) => x.id === content.id)
        if (idx >= 0) newAllFiles[idx] = content
        else newAllFiles.push(content)
        return newAllFiles
      })
    },
    [setAllFiles]
  )

  const handleUpdate = useCallback(
    (content: ProjectContentEx) => {
      setAllFiles((current) => {
        if (!current) return undefined

        const idx = current.findIndex((x) => x.id === content.id)
        if (idx < 0) return current

        const newAllFiles: ProjectContentEx[] = JSON.parse(JSON.stringify(current || []))
        newAllFiles[idx] = content
        return newAllFiles
      })
    },
    [setAllFiles]
  )

  const handleDelete = useCallback(
    (content: ProjectContent) => {
      setAllFiles((current) => {
        if (!current) return undefined

        const idx = current.findIndex((x) => x.id === content.id)
        if (idx < 0) return current

        const newAllFiles: ProjectContentEx[] = JSON.parse(JSON.stringify(current || []))
        newAllFiles.splice(idx, 1)
        return newAllFiles
      })
    },
    [setAllFiles]
  )

  useEffect(() => {
    if (!connection || connection.state !== HubConnectionState.Disconnected) return

    // Start the connection
    connection.start().then(() => {
      if (!connection) return

      connection.on('RecvFetch', (content: ProjectContentEx[]) => {
        const ready = content.filter((x) => x.accountId === project.account?.id && x.projectId === project.id)
        handleFetch(ready)
      })

      connection.on('RecvNew', (content: ProjectContentEx) => {
        if (content.accountId !== project.account?.id && content.projectId !== project.id) return
        handleNew(content)
      })

      connection.on('RecvUpdate', (content: ProjectContentEx) => {
        if (content.accountId !== project.account?.id && content.projectId !== project.id) return
        handleUpdate(content)
      })

      connection.on('RecvDelete', (content: ProjectContentEx) => {
        if (content.accountId !== project.account?.id && content.projectId !== project.id) return
        handleDelete(content)
      })

      connection.on('RecvDrawingExplodeStart', (content: ProjectContentEx[]) => {
        const ready = content.filter((x) => x.accountId === project.account?.id && x.projectId === project.id)
        handleFetch(ready)
      })
      connection.on('RecvDrawingExplodePage', (content: ProjectContentEx[]) => {
        const ready = content.filter((x) => x.accountId === project.account?.id && x.projectId === project.id)
        handleFetch(ready)
      })
      connection.on('RecvDrawingExplodeComplete', (content: ProjectContentEx[]) => {
        const ready = content.filter((x) => x.accountId === project.account?.id && x.projectId === project.id)
        handleFetch(ready)
      })

      handleConnected(connection)
    })
  }, [connection, project.id, handleConnected, handleFetch, handleNew, handleUpdate, handleDelete])

  const patch = useCallback(
    (id: string, patch: Patch[]) => {
      if (!connection || connection.state !== HubConnectionState.Connected) {
        return Promise.resolve()
      }
      return connection.send('Patch', project.id, id, patch).then(() => {})
    },
    [connection, project]
  )

  return {
    connection,
    category: projectFilesCategory,
    allFiles,
    files,
    patch,
    inFetch: projectFilesFetch
  }
}
