import React from 'react'
import { Select, SelectProps } from '../select'

export type SelectFieldProps = SelectProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const SelectField: React.FC<SelectFieldProps> = ({ label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label>{label}</label>}
    <Select {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
