import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import docWithCheck from 'components/src/img/docWithCheck.png'
import { NewExpertModel } from './model'

export const ExpertNextSteps: React.FC<{
  model: NewExpertModel
}> = ({ model }) => {
  const { t } = useTranslation()

  return (
    <>
      <h3 className="intro">{t('Onboarding.NextSteps')}</h3>
      <hr />
      <br />

      <div className="ui-text-center ui-text-bold" style={{ fontSize: '2rem' }}>
        {model.givenName} {model.familyName}
      </div>
      <br />
      <br />

      <div className="ui-text-center">
        <img alt="" src={docWithCheck} style={{ width: 170 }} />

        <p style={{ width: '75%', maxWidth: 300, margin: '30px auto 0 auto' }}>{t('Onboarding.Expert.NextSteps')}</p>
      </div>
      <br />
      <br />

      <div className="ui-flex" style={{ justifyContent: 'center' }}>
        <Link to="/dashboard" replace className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '75%' }}>
          {t('Onboarding.Expert.Dashboard')}
        </Link>
      </div>
    </>
  )
}
