import React, { useEffect } from 'react'
import { useIsAuthenticated } from '@azure/msal-react'
import { logout } from 'services/src/aad'
import { useHistory } from 'react-router-dom'

export const Logout: React.FC = () => {
  const isAuthenticated = useIsAuthenticated()
  const history = useHistory()

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace('/')
      return
    }
    logout().then(() => {})
  }, [isAuthenticated])

  return null
}
