import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'

export interface MultiSelectItem {
  label: string
  selected?: boolean
}

export interface MultiSelectProps {
  className?: string
  style?: React.CSSProperties
  items: MultiSelectItem[]
  onToggle: (item: MultiSelectItem) => void
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ className, style, items, onToggle }) => (
  <div className={`ui-multi-select ${className || ''}`} style={style}>
    <div>
      {items.map((item, idx) => (
        <div key={idx} className={`ui-multi-select-item${item.selected ? ' ui-multi-select-item-selected' : ''}`} role="row" tabIndex={-1} onKeyDown={() => {}} onClick={() => onToggle(item)}>
          <div>
            <FontAwesomeIcon icon={item.selected ? faCheckSquare : faSquare} />
          </div>
          <div style={{ marginLeft: 10, flex: 1 }}>{item.label}</div>
        </div>
      ))}
    </div>
  </div>
)
