import React from 'react'
import { CardSelect, CardSelectProps } from '../cardSelect'

export type CardSelectFieldProps = CardSelectProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const CardSelectField: React.FC<CardSelectFieldProps> = ({ label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label>{label}</label>}
    <CardSelect {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
