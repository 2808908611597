import React from 'react'
import { AutoComplete, AutoCompleteProps } from '../autoComplete'

export type AutoCompleteFieldProps = AutoCompleteProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({ value, onValueChange, suggestions, loading, placeholder, render, onSelectSuggestion, className, label, error, ...rest }) => (
  <div className={`ui-form-group${error ? ' ui-has-error' : ''} ${className || ''}`}>
    {label && <label>{label}</label>}

    <AutoComplete suggestions={suggestions} value={value} onValueChange={onValueChange} placeholder={placeholder} onSelectSuggestion={onSelectSuggestion} render={render} {...rest} />

    {error && <div className="ui-error">{error}</div>}
  </div>
)
