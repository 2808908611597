import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { getGeolocation } from '../api'

export interface Geo {
  [key: string]: any
  ready: boolean
}

export const geoAtom = atom<Geo>({
  key: 'goeAccount',
  default: { ready: false }
})

/** Geolocation hook. */
export const useGeolocation = (): Geo => {
  const [current, setCurrent] = useRecoilState(geoAtom)

  useEffect(() => {
    if (current.ready) return
    getGeolocation()
      .then((geo) => setCurrent({ ...geo, ready: true }))
      .catch(() => setCurrent({ ready: false }))
  }, [current])

  return current
}
