import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import './style.scss'
import { useOverlayControl } from 'services/src/state'

export type SelectProps = {
  className?: string
  style?: React.CSSProperties
  items: any[]
  selected?: any
  labelField?: string
  valueField?: string
  placeholder?: string
  renderItem?: (item: any) => JSX.Element
  itemValue?: (item: any) => string
  renderSelected?: (item: any) => JSX.Element
  onSelectedChange?: (item: any) => boolean | void
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  maxDropDown?: number
  noToggle?: boolean
  disabled?: boolean
  onClear?: () => void
  noSelectedOnTab?: boolean
}

export const Select: React.FC<SelectProps> = ({
  items,
  selected,
  valueField,
  labelField,
  placeholder,
  renderItem,
  itemValue,
  renderSelected,
  onSelectedChange,
  open,
  onOpen,
  onClose,
  maxDropDown = 6,
  noToggle,
  className,
  style,
  disabled,
  onClear,
  noSelectedOnTab,
  ...props
}) => {
  const { addOverlay, removeOverlay } = useOverlayControl()

  const [isOpen, setIsOpen] = useState(open)
  const [highlight, setHighlight] = useState(-1)
  const itemsRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<HTMLDivElement>(null)
  const ignoreItemsBlur = useRef<boolean>(false)

  const [localItems, setLocalItems] = useState<any[]>([])
  useEffect(() => {
    let highlight = -1
    const localItems = items.map((item: any, index) => {
      let isSel = false
      if (selected) {
        if (item[valueField || 'id'] === selected[valueField || 'id']) isSel = true
        else if (item[valueField || 'id'] === selected) isSel = true
      }
      if (isSel) highlight = index
      return {
        src: { ...item },
        selected: isSel
      }
    })
    setLocalItems(localItems)
    setHighlight(highlight)
  }, [items, selected, setHighlight])

  const selectedItem = useCallback(
    (e: React.MouseEvent, src: any) => {
      e.preventDefault()
      e.stopPropagation()
      if (onSelectedChange) {
        if (!onSelectedChange(src)) {
          setIsOpen(false)
          if (selectRef.current) selectRef.current.focus()
        }
      } else {
        setIsOpen(false)
        if (selectRef.current) selectRef.current.focus()
      }
    },
    [items, onSelectedChange, setIsOpen]
  )

  const itemsBlur = useCallback(() => {
    setTimeout(() => {
      if (ignoreItemsBlur.current) {
        ignoreItemsBlur.current = false
        return
      }
      setIsOpen(false)
    })
  }, [])

  const [keys, setKeys] = useState('')

  useEffect(() => {
    setIsOpen(open && !disabled)
  }, [open, setIsOpen])

  useEffect(() => {
    if (isOpen) {
      if (onOpen) onOpen()
      addOverlay(Select.name)
    } else {
      if (onClose) onClose()
      removeOverlay(Select.name)
    }
    setHighlight(localItems.findIndex((x) => x.selected))
    setKeys('')

    return () => {
      removeOverlay(Select.name)
    }
  }, [isOpen, setKeys, setHighlight])

  useLayoutEffect(() => {
    if (itemsRef.current && selectRef.current) {
      if (isOpen) {
        const { left, top, height, width } = selectRef.current.getBoundingClientRect()
        itemsRef.current.style.left = `${left}px`
        itemsRef.current.style.top = `${top + height}px`
        itemsRef.current.style.minWidth = `${width}px`
      }
    }
  }, [isOpen, selectRef, itemsRef])

  useLayoutEffect(() => {
    if (!itemsRef.current || !isOpen || !selectRef.current) return
    let height = 0
    let selectedTop = 0
    for (let i = 0; i < itemsRef.current.children.length; i++) {
      const c = itemsRef.current.children[i]
      const rect = c.getBoundingClientRect()
      if (c.className.includes('selected')) selectedTop = height
      height += rect.height
    }
    const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
    const maxHeight = Math.ceil(avgItemHeight * (maxDropDown - 0.5))
    itemsRef.current.style.maxHeight = `${maxHeight}px`

    const rect = itemsRef.current.getBoundingClientRect()

    if (itemsRef.current.clientHeight + rect.y > window.innerHeight) {
      itemsRef.current.style.top = `${rect.y - itemsRef.current.clientHeight - selectRef.current.clientHeight - 12}px`
    }

    if (selectedTop > maxHeight - avgItemHeight) {
      itemsRef.current.scrollTop = selectedTop - (maxHeight - avgItemHeight * 2)
    } else itemsRef.current.scrollTop = 0

    setTimeout(() => {
      if (itemsRef.current) itemsRef.current.focus()
    })
  }, [isOpen, itemsRef, maxDropDown])

  const toggle = useCallback(() => {
    if (disabled) return

    ignoreItemsBlur.current = isOpen === true

    setIsOpen(!isOpen)
    setHighlight(localItems.findIndex((x) => x.selected))
    setKeys('')
  }, [disabled, isOpen, setIsOpen, setHighlight, setKeys])

  const keyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'ArrowDown') {
        e.preventDefault()
        e.stopPropagation()
        if (!isOpen) {
          setHighlight(localItems.findIndex((x) => x.selected))
          setIsOpen(true)
          return
        }
        if (highlight < items.length - 1) {
          const h = highlight + 1
          setHighlight(h)

          if (!itemsRef.current) return

          let height = 0
          let highlightTop = 0
          for (let i = 0; i < itemsRef.current.children.length; i++) {
            const c = itemsRef.current.children[i]
            const rect = c.getBoundingClientRect()
            if (i === h) highlightTop = height
            height += rect.height
          }
          const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
          const maxHeight = Math.ceil(avgItemHeight * (maxDropDown - 0.5))

          if (highlightTop > maxHeight - avgItemHeight) {
            itemsRef.current.scrollTop = highlightTop - (maxHeight - avgItemHeight * 2)
          }
        }
      } else if (e.code === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()
        if (!isOpen) {
          setHighlight(localItems.findIndex((x) => x.selected))
          setIsOpen(true)
          return
        }
        if (highlight > 0) {
          const h = highlight - 1
          setHighlight(h)

          if (!itemsRef.current) return

          let height = 0
          let highlightTop = 0
          for (let i = 0; i < itemsRef.current.children.length; i++) {
            const c = itemsRef.current.children[i]
            const rect = c.getBoundingClientRect()
            if (i === h) highlightTop = height
            height += rect.height
          }
          const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
          const maxHeight = Math.ceil(avgItemHeight * (maxDropDown - 0.5))

          if (highlightTop > 0) {
            itemsRef.current.scrollTop = highlightTop - (maxHeight - avgItemHeight * 2)
          }
        }
      } else if (e.code === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(false)
        setHighlight(localItems.findIndex((x) => x.selected))
        if (selectRef.current) selectRef.current.focus()
      } else if (e.code === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        if (!isOpen) {
          setHighlight(localItems.findIndex((x) => x.selected))
          setIsOpen(true)
          return
        }
        if (highlight < 0) return
        if (onSelectedChange) onSelectedChange(localItems[highlight].src)
        setIsOpen(false)
        if (selectRef.current) selectRef.current.focus()
      } else if (e.code === 'Tab') {
        if (!isOpen && highlight < 0) return
        if (!e.shiftKey) {
          if (onSelectedChange && !noSelectedOnTab) onSelectedChange(localItems[highlight].src)
        }
        setIsOpen(false)
        setHighlight(localItems.findIndex((x) => x.selected))
        // if (selectRef.current) selectRef.current.focus();
      } else if (e.key.length === 1 && /[\w\d]/.test(e.key)) {
        setKeys(`${keys}${e.key}`)
      } else if (e.code === 'Backspace' || e.code === 'Delete') {
        setKeys('')
        setHighlight(-1)
      } else if (e.key === ' ') {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [isOpen, highlight, setHighlight, localItems, onSelectedChange, setHighlight, maxDropDown, keys, setKeys]
  )

  useEffect(() => {
    if (!keys.length) return

    const kx = keys.replace(/[^a-zA-Z]/g, '').toLowerCase()
    const h = items.findIndex((i) => {
      const v = ((itemValue ? itemValue(i) : i[labelField || 'label']) || '').toLowerCase().replace(/[^a-zA-Z]/g, '')
      return v.startsWith(kx)
    })
    if (h < 0 || h === highlight) return

    setHighlight(h)

    if (!itemsRef.current) return

    let height = 0
    let highlightTop = 0
    for (let i = 0; i < itemsRef.current.children.length; i++) {
      const c = itemsRef.current.children[i]
      const rect = c.getBoundingClientRect()
      if (i === h) highlightTop = height
      height += rect.height
    }
    const avgItemHeight = Math.ceil(height / itemsRef.current.children.length)
    const maxHeight = Math.ceil(avgItemHeight * (maxDropDown - 0.5))

    itemsRef.current.scrollTop = highlightTop - (maxHeight - avgItemHeight * 2)
  }, [items, keys, itemValue, labelField, setHighlight, itemsRef])

  return (
    <>
      <div
        {...props}
        className={`ui-select${noToggle ? ' no-toggle' : ''}${disabled ? ' disabled' : ''} ${selected ? 'has-selection' : 'no-selection'} ${className || ''}`}
        style={style}
        tabIndex={0}
        role="listbox"
        onMouseDown={toggle}
        onKeyDown={keyDown}
        onFocus={(e) => {
          if (disabled) e.currentTarget.blur()
        }}
        ref={selectRef}
      >
        {selected ? (
          <div className="selected-item">
            <div>{renderSelected ? renderSelected(selected) : selected[labelField || 'label']}</div>
          </div>
        ) : (
          <div className="selected-item">
            <div className="ui-text-muted">{placeholder || ''}</div>
          </div>
        )}
        <div className="selected-actions">
          {onClear && selected && (
            <div
              style={{ marginTop: 2 }}
              role="button"
              tabIndex={-1}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsOpen(false)
                onClear()
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          )}
          <div style={{ marginTop: 2 }}>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </div>
        </div>

        <div className={`items${isOpen && localItems.length > 0 ? ' open' : ''}`} role="listbox" tabIndex={0} ref={itemsRef} onBlur={itemsBlur}>
          {localItems.map((item, idx) => (
            <div
              key={idx}
              role="button"
              tabIndex={-1}
              className={`${item.selected ? 'selected' : ''}
                         ${highlight === idx ? 'highlight' : ''}`}
              onMouseDown={(e) => selectedItem(e, item.src)}
            >
              {renderItem ? renderItem(item.src) : item.src[labelField || 'label']}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
