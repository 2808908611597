import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { i84nFormat } from 'services/src/i18n'

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: i84nFormat
    },
    backend: {
      backends: [
        resourcesToBackend((lng, ns, cb) => {
          import(`locales/${lng.toLowerCase()}/${ns}.json`).then(({ default: resources }) => cb(null, resources)).catch((error) => cb(error, null))
        })
      ]
    }
  } as InitOptions)
  .then(() => {})

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

export default i18n
