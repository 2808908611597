import React from 'react'
import { MultiSelect, MultiSelectProps } from '../multiSelect'

export * from '../multiSelect'

export type MultiSelectFieldProps = MultiSelectProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({ label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label>{label}</label>}
    <MultiSelect {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
