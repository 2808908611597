import axios from 'axios'
import apiProxy from './apiProxy'
import { getAccessToken } from '../aad'
import { SmartDoc, SmartDocCounts, SmartDocRichContent } from '../dto/smartDocs'

export interface GetSmartDocsParams {
  [key: string]: string | number | undefined | null
  offset?: number
  limit?: number
  sort?: string
  sortDir?: string
  filter?: string
}

/** Fetch smart documents. */
export const getSmartDocs = apiProxy('getSmartDocs', async (params: GetSmartDocsParams): Promise<{ total: number; docs: SmartDoc[] }> => {
  await getAccessToken()

  const qs: string[] = []
  Object.keys(params).forEach((k) => {
    const val = params[k]
    if (val === undefined || val === null) return

    if (typeof val === 'number') qs.push(`${k}=${encodeURIComponent(val.toString())}`)
    else qs.push(`${k}=${encodeURIComponent(val)}`)
  })

  const { data } = await axios.get(`${process.env.REACT_APP_CONTENT_API}/smart-docs${qs.length ? `?${qs.join('&')}` : ''}`)
  return data
})

/** Fetch smart document counts. */
export const getSmartDocCounts = apiProxy('getSmartDocCounts', async (): Promise<SmartDocCounts> => {
  await getAccessToken()

  const { data } = await axios.get(`${process.env.REACT_APP_CONTENT_API}/smart-docs/counts`)
  return data
})

/** Fetch a smart document. */
export const getSmartDocById = apiProxy('getSmartDocById', async (id: string): Promise<SmartDoc> => {
  await getAccessToken()

  const { data } = await axios.get(`${process.env.REACT_APP_CONTENT_API}/smart-docs/${id}`)
  return data
})

/** Fetch a rich content preview. */
export const getRichContentPreview = apiProxy('getRichContentPreview', async (content: SmartDocRichContent): Promise<SmartDoc> => {
  await getAccessToken()

  const { data } = await axios.post(`${process.env.REACT_APP_CONTENT_API}/smart-docs/preview`, content, {
    responseType: 'arraybuffer'
  })
  return data
})

/** Create a smart document. */
export const createSmartDoc = apiProxy('createSmartDoc', async (doc: SmartDoc): Promise<SmartDoc> => {
  await getAccessToken()

  const { data } = await axios.post(`${process.env.REACT_APP_CONTENT_API}/smart-docs`, doc)
  return data
})

/** Update a smart document. */
export const updateSmartDoc = apiProxy('updateSmartDoc', async (doc: SmartDoc): Promise<SmartDoc> => {
  await getAccessToken()

  const { data } = await axios.put(`${process.env.REACT_APP_CONTENT_API}/smart-docs/${doc.id}`, doc)
  return data
})

/** Delete a smart document. */
export const deleteSmartDoc = apiProxy('deleteSmartDoc', async (doc: SmartDoc | string): Promise<void> => {
  await getAccessToken()

  const id = typeof doc === 'string' ? doc : doc.id

  await axios.delete(`${process.env.REACT_APP_CONTENT_API}/smart-docs/${id}`)
})
