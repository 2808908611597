import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import { Layout } from '../layout/public'

export const NotFound: React.FC<{
  back?: string
  backLabel?: string
}> = ({ back, backLabel }) => {
  const { t } = useTranslation()

  return (
    <Layout className="error-page">
      <div className="error-page-code">
        <FontAwesomeIcon icon={faExclamationTriangle} /> {t('General.Errors.NotFoundTitle')}
      </div>
      <div className="error-page-text ui-text-center">{t('General.Errors.NotFound')}</div>

      <div className="ui-text-center ui-text-lg">
        {back && (
          <div style={{ marginBottom: 20 }}>
            <Link to={back} className="ui-info">
              <FontAwesomeIcon icon={faArrowLeft} /> {backLabel || t('General.Back')}
            </Link>
          </div>
        )}
        <div>
          <a href={process.env.REACT_APP_CC_SITE} className="ui-info">
            <FontAwesomeIcon icon={faArrowLeft} /> {t('General.BackToCC')}
          </a>
        </div>
      </div>
    </Layout>
  )
}
