import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons'

import { lookupAddress, useCountries } from 'services/src/api'
import { Address } from 'services/src/dto/account'

import { useGeolocation } from 'services/src/state'
import { AutoCompleteField } from '../autoCompleteField'
import { TextField } from '../textField'
import { SelectField } from '../selectField'

export interface AddressFieldProps {
  address?: Address
  onChange: (address?: Address) => void
  countryLabel?: JSX.Element | string | null
  countrySample?: string
  streetLabel?: JSX.Element | string | null
  streetSample?: string
  street2Label?: JSX.Element | string | null
  street2Sample?: string
  cityLabel?: JSX.Element | string | null
  citySample?: string
  postalCodeLabel?: JSX.Element | string | null
  postalCodeSample?: string
  disabled?: boolean
  errors?: any
  error?: string
  variant?: 'all' | 'addressOnly'
}

export const AddressField: React.FC<AddressFieldProps> = ({
  address,
  onChange,
  countryLabel,
  countrySample,
  streetLabel,
  streetSample,
  street2Label,
  street2Sample,
  cityLabel,
  citySample,
  postalCodeSample,
  postalCodeLabel,
  disabled,
  errors = {},
  error,
  variant
}) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()

  const [street, setStreet] = useState<string>('')
  const [suggestions, setSuggestions] = useState<any[]>([])
  const lookupTimer = useRef<any>()

  const [street2, setStreet2] = useState<string>('')

  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')

  const countries = useCountries()
  const [country, setCountry] = useState<any>()

  useEffect(() => {
    if (!countries) return
    const cc = geo?.toUpperCase() || 'US'
    setCountry(countries.find((x) => x.alpha2Code === cc))
  }, [countries, geo])

  useEffect(() => {
    setCity(address?.municipality || '')
    setState(address?.countrySubdivisionName || '')

    if (variant === 'addressOnly') {
      setStreet(address?.freeformAddress || '')
    } else if (address?.streetNameAndNumber) setStreet(address.streetNameAndNumber)
    else if (address?.streetNumber && address.streetName) setStreet(`${address.streetNumber} ${address.streetName}`)
    else if (address?.street) setStreet(address.street)
    else if (address?.streetName) setStreet(address.streetName)
    else setStreet(address?.freeformAddress || '')

    if (address?.buildingNumber) setStreet2(address?.buildingNumber)

    if (address?.postalCode) setPostalCode(address.postalCode)
    else if (address?.extendedPostalCode) setPostalCode(address.extendedPostalCode)
    else setPostalCode('')

    if (countries) {
      let c = countries.find((x) => x.alpha2Code === address?.countryCode)
      if (!c) c = countries.find((x) => x.alpha2Code === 'US')
      setCountry(c)
    }
  }, [address, setCity, setStreet, setPostalCode, setCountry, variant, countries])

  return (
    <div className={`ui-row${error ? ' ui-has-error' : ''}`}>
      <div className={`ui-col-12${variant === 'addressOnly' ? ' ui-col-lg-5' : ''}`}>
        <SelectField
          items={countries || []}
          selected={country}
          disabled={disabled}
          label={countryLabel === null ? <>&nbsp;</> : countryLabel || t('General.Address.Country')}
          placeholder={countrySample === null ? undefined : countrySample || t('General.Address.CountrySample')}
          error={error || errors.country}
          onSelectedChange={(c) => {
            onChange({
              countryCode: c.alpha2Code,
              country: c.name
            })
          }}
          labelField="name"
          valueField="alpha2Code"
        />
      </div>

      <div className="ui-col-12 ui-col-lg-7">
        <AutoCompleteField
          suggestions={suggestions}
          value={street || ''}
          disabled={disabled}
          label={streetLabel === null ? <>&nbsp;</> : streetLabel || t('General.Address.Street')}
          placeholder={streetSample === null ? undefined : streetSample || t('General.Address.StreetSample')}
          error={errors.street}
          onValueChange={(v) => {
            clearTimeout(lookupTimer.current)

            if (!v) {
              onChange(undefined)
              return
            }

            onChange({
              ...address,
              freeformAddress: v,
              streetNameAndNumber: undefined,
              streetNumber: undefined,
              streetName: undefined,
              street: undefined
            })

            lookupTimer.current = setTimeout(() => {
              lookupAddress(v.trim(), country?.alpha2Code || 'US').then((result) => setSuggestions(result))
            }, 100)
          }}
          onSelectSuggestion={(address: Address) => {
            onChange(address)
            setSuggestions([])
          }}
          render={(address: Address) => (
            <div className="ui-text-ellipsis ui-text-sm">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              &nbsp;&nbsp;{address.freeformAddress}
            </div>
          )}
        />
      </div>

      {variant !== 'addressOnly' && (
        <>
          <div className="ui-col-12 ui-col-lg-5">
            <TextField
              name="street2"
              value={street2}
              disabled={disabled}
              label={street2Label || t('General.Address.Street2')}
              placeholder={street2Sample || t('General.Address.Street2Sample')}
              error={errors.street2}
              onChange={(v) => onChange({ ...address, buildingNumber: v || undefined })}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-6">
            <TextField
              name="city"
              value={city}
              disabled={disabled}
              label={cityLabel || t('General.Address.City')}
              placeholder={citySample || t('General.Address.CitySample')}
              error={errors.city}
              onChange={(v) => onChange({ ...address, municipality: v || undefined, municipalitySubdivision: undefined })}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-3">
            <TextField
              name="state"
              value={state}
              disabled={disabled}
              label={cityLabel || t('General.Address.State')}
              placeholder={citySample || t('General.Address.StateSample')}
              error={errors.state}
              onChange={(v) => onChange({ ...address, countrySubdivisionName: v || undefined })}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-3">
            <TextField
              name="postalCode"
              value={postalCode}
              disabled={disabled}
              label={postalCodeLabel || t('General.Address.PortalCode')}
              placeholder={postalCodeSample || t('General.Address.PortalCodeSample')}
              error={errors.postalCode}
              onChange={(v) => onChange({ ...address, postalCode: v || undefined, extendedPostalCode: undefined })}
            />
          </div>
        </>
      )}
    </div>
  )
}
