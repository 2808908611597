import React, { SVGProps } from 'react'
import './style.scss'

const Loading: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => {
  const stroke = '#000000'
  const fill = '#ffffff'

  return (
    <svg viewBox="0 0 161 161" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-loading ${className || ''}`} {...rest}>
      <g>
        <path
          stroke={stroke}
          fill={fill}
          d="M102.593138,34.7344522 L117.658613,9.81313811 C111.58862,6.77057874 105.691999,4.45558122 99.9687497,2.86814553 C94.2455001,1.28070984 88.3488792,0.32466133 82.2788869,0 L82.2788869,29.7080806 C84.883782,29.4994801 88.0419447,29.8543409 91.7533751,30.7726631 C95.4648054,31.6909853 99.0780599,33.0115817 102.593138,34.7344522 Z"
          id="seg-1"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M122.581195,52.5708064 L147.621125,37.5254971 C144.157893,32.3184177 140.368691,27.6542216 136.253519,23.5329087 C132.138347,19.4115958 127.580905,15.7166937 122.581195,12.4482023 L106.73048,37.5254971 C109.369378,39.1481014 112.123058,41.3207592 114.991522,44.0434705 C117.859985,46.7661817 120.389876,49.608627 122.581195,52.5708064 Z"
          id="seg-2"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M129.937786,77.0751837 L159.82735,77.0751837 C159.09879,69.5136881 157.979644,62.9998663 156.469912,57.5337182 C154.960179,52.0675702 152.861657,46.9314783 150.174344,42.1254426 L124.884133,56.7419535 C126.422835,60.5674968 127.580334,64.0419701 128.356629,67.1653732 C129.132925,70.2887763 129.659977,73.5920465 129.937786,77.0751837 Z"
          id="seg-3"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M124.884133,102.564671 C126.591834,99.2629578 127.848179,95.9359643 128.65317,92.5836902 C129.458161,89.231416 129.831606,85.7713745 129.773503,82.2035655 L159.82735,82.2035655 C159.718932,87.9712947 158.825355,93.9306835 157.146619,100.081732 C155.467882,106.23278 153.108601,112.192169 150.068776,117.959898 L124.884133,102.564671 Z"
          id="seg-4"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M106.73048,122.219415 C109.669428,120.388492 112.457705,118.17333 115.095311,115.573929 C117.732917,112.974527 120.103862,110.105195 122.208146,106.965934 L147.621125,122.219415 C144.43162,127.2011 140.652896,131.882693 136.284952,136.264194 C131.917009,140.645696 127.22474,144.461389 122.208146,147.711274 L106.73048,122.219415 Z"
          id="seg-5"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M82.2788869,130.206453 C85.8891834,129.897781 89.2924867,129.308455 92.488797,128.438475 C95.6851073,127.568494 98.9480479,126.343384 102.277619,124.763144 L117.658613,149.800702 C112.392621,152.864566 106.776725,155.236104 100.810926,156.915317 C94.8451278,158.594529 88.6677813,159.542472 82.2788869,159.759146 L82.2788869,130.206453 Z"
          id="seg-6"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M56.8089718,124.763144 C59.8621011,126.456537 63.0837236,127.720593 66.4738393,128.555312 C69.8639551,129.39003 73.365117,129.781267 76.9773251,129.729022 L76.9773251,159.759277 C70.7888481,159.723424 64.665472,158.929243 58.607197,157.376735 C52.548922,155.824226 46.9369805,153.611041 41.7713725,150.737179 L56.8089718,124.763144 Z"
          id="seg-7"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M36.6222329,106.965934 C38.6871874,109.784779 41.0458753,112.471837 43.6982966,115.027107 C46.3507179,117.582377 49.2113511,119.92347 52.2801961,122.050386 L37.8825325,147.711274 C32.0518511,143.8379 27.0171763,139.847247 22.7785081,135.739317 C18.5398399,131.631386 14.7486608,127.068409 11.4049707,122.050386 L36.6222329,106.965934 Z"
          id="seg-8"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M29.2662343,82.2035655 C29.3601816,85.5698986 29.8767562,89.0799567 30.8159581,92.73374 C31.75516,96.3875232 33.0516377,99.930794 34.7053912,103.363552 L9.3469755,117.959898 C6.70696989,112.903949 4.58136327,107.294183 2.97015567,101.130598 C1.35894806,94.967013 0.368896168,88.6580023 -2.27373675e-13,82.2035655 L29.2662343,82.2035655 Z"
          id="seg-9"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M34.7053912,56.8125303 L9.55099578,42.1254426 C6.56561515,46.5347638 4.23401132,51.8092416 2.55618429,57.9488758 C0.878357256,64.08851 0.0262958261,70.4639459 -2.27373675e-13,77.0751837 L29.5823471,77.0751837 C29.435876,73.6539559 29.7978539,70.2219869 30.6682806,66.7792767 C31.5387074,63.3365664 32.884411,60.0143176 34.7053912,56.8125303 Z"
          id="seg-10"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M12.5810527,37.8955804 C16.3210291,31.5175744 19.8518451,26.606063 23.1735009,23.1610461 C26.4951566,19.7160293 31.1834714,16.1450814 37.2384453,12.4482023 L52.2801961,37.8955804 C48.6457883,40.2987494 45.8948754,42.4012258 44.0274572,44.2030096 C42.1600391,46.0047933 40.0035922,48.7940589 37.5581166,52.5708064 L12.5810527,37.8955804 Z"
          id="seg-11"
        />
        <path
          stroke={stroke}
          fill={fill}
          d="M41.7713725,9.95251243 C46.7584654,7.11877286 52.268199,4.84924497 58.3005735,3.14392875 C64.332948,1.43861254 70.5585318,0.390636286 76.9773251,0 L76.9773251,29.6742749 C73.7324132,29.7594806 70.3565893,30.2460057 66.8498534,31.1338502 C63.3431176,32.0216946 60.0568491,33.2218953 56.991048,34.7344522 L41.7713725,9.95251243 Z"
          id="seg-12"
        />
      </g>
    </svg>
  )
}
export default Loading
