import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

export const overlayLayerCountAtom = atom<string[]>({
  key: 'overlayLayers',
  default: []
})

export interface OverlayControlHook {
  overlays: string[]
  addOverlay: (name: string) => void
  removeOverlay: (name: string) => void
}
export const useOverlayControl = (): OverlayControlHook => {
  const [current, setCurrent] = useRecoilState(overlayLayerCountAtom)

  const add = useCallback(
    (name: string) => {
      // document.body.classList.add('no-scroll');
      setCurrent((c) => [...c, name])
    },
    [setCurrent]
  )

  const del = useCallback(
    (name: string) => {
      setCurrent((c) => {
        const cx = [...c]
        const idx = cx.findIndex((x) => x === name)
        if (idx >= 0) cx.splice(idx, 1)
        if (!cx.length) document.body.classList.remove('no-scroll')
        return cx
      })
    },
    [setCurrent]
  )

  return {
    overlays: current,
    addOverlay: add,
    removeOverlay: del
  }
}
