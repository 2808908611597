export interface ApiProxyFn<T extends any[], U extends Promise<any>> {
  (...args: T): U
}

const state: { [key: number]: Promise<any> | undefined } = {}

/**
 * API function wrapper.
 * @param {string} name - The function name.
 * @param {ApiProxyFn} fn - The function to wrap.
 */
const apiProxy = <T extends any[], U extends Promise<any>>(name: string, fn: ApiProxyFn<T, U>) => {
  const hashCode = (s: string) =>
    s
      .split('')
      // eslint-disable-next-line no-bitwise
      .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

  return (...args: T): U => {
    const h = hashCode(`${name}${args.map((a) => a?.toString() || '').join('')}`)
    if (state[h]) return state[h] as U

    state[h] = fn(...args)
    state[h]!.finally(() => delete state[h])
    return state[h] as U
  }
}

export default apiProxy
