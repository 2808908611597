import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'

import { ExpertRole } from 'services/src/dto/account'

import { CurrencyInputField } from 'components/src/currencyInputField'
import 'react-phone-number-input/style.css'
import { TextField } from 'components/src/textField'
import { useGeolocation, useRights, UserType } from 'services/src/state'
import { PhoneInputField } from 'components/src/phoneInputField'
import { CardSelectField } from 'components/src/cardSelectField'
import { Dropzone } from 'components/src/dropzone'
import { AddressField } from 'components/src/addressField'
import { MAX_PHOTO_SIZE, PHOTO_TYPES } from 'services/src/common'

import { NewExpertModel } from './model'

export interface ExpertProfileProps {
  model: NewExpertModel
  onChangeModel: (model: NewExpertModel) => void
  onNext: () => void
}

export const ExpertProfile: React.FC<ExpertProfileProps> = ({ model, onChangeModel, onNext }) => {
  const { t } = useTranslation()
  const rights = useRights()
  const { country_code: geo } = useGeolocation()

  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const expertRoles = useMemo<{ id: ExpertRole; label: string }[]>(
    () => [
      { id: ExpertRole.CostEngineer, label: t(`General.ExpertRoles.${ExpertRole.CostEngineer}`) },
      { id: ExpertRole.CostEstimator, label: t(`General.ExpertRoles.${ExpertRole.CostEstimator}`) },
      { id: ExpertRole.QuantitySurveyor, label: t(`General.ExpertRoles.${ExpertRole.QuantitySurveyor}`) }
    ],
    [t]
  )
  const [selectedExpertRolesIdx] = useState<number>(() => expertRoles.findIndex((x) => x.id === model?.expertRole))

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return
      const picture = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      })
      onChangeModel({ ...model, picture })
    },
    [model, onChangeModel]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}

      if (!model.personalAddress) errors.personalAddress = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNext()
    },
    [onNext, t]
  )

  if (!model) return null

  return (
    <form noValidate onSubmit={submit}>
      <h3 className="intro ui-text">{t('Onboarding.Expert.Intro', { name: model.givenName })}</h3>
      <hr />
      <br />

      <div className="ui-text-sm ui-text-muted" style={{ marginBottom: 15 }}>
        {t('Onboarding.Expert.Step1', { N: 1, M: rights?.userType === UserType.provider ? 4 : 5 })}
      </div>

      {selectedExpertRolesIdx >= 0 && (
        <CardSelectField
          variant="primary"
          items={expertRoles.map((x) => x.label)}
          readonly
          selected={selectedExpertRolesIdx >= 0 ? [selectedExpertRolesIdx] : []}
          onClick={() => {}}
          label={t('Onboarding.Expert.ExpertRole')}
        />
      )}

      <div className="ui-form-group">
        <label>{t('Onboarding.Picture')}</label>
        <div className="ui-flex" style={{ justifyContent: 'center' }}>
          <Dropzone onDrop={onDropPicture} variant="round" accept={PHOTO_TYPES} maxFiles={1} maxSize={MAX_PHOTO_SIZE} rejectLabel={t('Onboarding.InvalidPicture')}>
            {(() => {
              if (model.picture?.preview || model.pictureUrl)
                return (
                  <div
                    className="bg-preview"
                    style={{
                      width: 130,
                      height: 130,
                      backgroundImage: `url('${model.picture?.preview || model.pictureUrl}')`
                    }}
                  />
                )

              return (
                <div className="empty" style={{ width: 130, height: 130 }}>
                  <FontAwesomeIcon icon={faCamera} />
                </div>
              )
            })()}
          </Dropzone>
        </div>
      </div>

      <div className="ui-row">
        <div className={rights?.userType === UserType.expert ? 'ui-col-8' : 'ui-col-12'}>
          <TextField name="email" value={model.emails?.length ? model.emails[0].email : ''} disabled label={t('Onboarding.Email')} onChange={() => {}} placeholder={t('Onboarding.EmailPlaceholder')} />
        </div>

        {rights?.userType === UserType.expert && (
          <div className="ui-col-4">
            <CurrencyInputField
              amount={model.hourlyRate ? model.hourlyRate.toFixed(2) : ''}
              name="hourlyRate"
              error={errors.hourlyRate}
              digits={2}
              disabled
              label={t('Onboarding.Expert.HourlyRate')}
              placeholder={t('General.Currency.Value', { value: 75 })}
              onChange={(hourlyRate) => {
                clearError('hourlyRate')
                onChangeModel({ ...model, hourlyRate })
              }}
            />
          </div>
        )}
      </div>

      <AddressField
        address={model.personalAddress}
        streetLabel={t('Settings.EditExpert.PersonalAddress')}
        errors={errors.personalAddress ? { street: errors.personalAddress } : undefined}
        onChange={(personalAddress) => {
          clearError('personalAddress')
          onChangeModel({ ...model, personalAddress })
        }}
        variant="addressOnly"
      />

      <PhoneInputField
        label={t('Onboarding.Expert.MobilePhone')}
        placeholder={t('Onboarding.PhonePlaceholder')}
        value={model.mobilePhone}
        error={errors.mobilePhone}
        enableSearch
        onChange={(mobilePhone) => {
          clearError('mobilePhone')
          onChangeModel({ ...model, mobilePhone })
        }}
        country={geo?.country_code?.toLowerCase() || 'us'}
      />

      <div className="ui-row">
        <div className="ui-col-8">
          <PhoneInputField
            label={t('Onboarding.Phone')}
            placeholder={t('Onboarding.PhonePlaceholder')}
            value={model.phone}
            error={errors.phone}
            enableSearch
            onChange={(phone) => {
              clearError('phone')
              onChangeModel({ ...model, phone })
            }}
            country={geo?.country_code?.toLowerCase() || 'us'}
          />
        </div>
        <div className="ui-col-4">
          <TextField
            name="extension"
            value={model.extension}
            label={t('Onboarding.Extension')}
            onChange={(extension) => {
              clearError('extension')
              onChangeModel({ ...model, extension })
            }}
            placeholder={t('Onboarding.ExtensionPlaceholder')}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-12">
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
            {t('Onboarding.Next')}
          </button>
        </div>
      </div>
    </form>
  )
}
